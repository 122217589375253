import { Box, Flex, Typography } from "components";
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Button } from "components/_form";
import { AccessModal } from "components/AccessModal";
import { useNavigate } from "react-router-dom";
import { useAccount } from "hooks/useAccount";
import { IExercise } from "types/exercises";
import { FILE_URL } from "constants/paths";
import { P } from "components/Typography/Typography";
import Thumbnail from "assets/images/exercise-thumbnail.png";

export interface IExerciseCard {
  exercise: {
    id: IExercise["id"];
    title: IExercise["title"];
    isFree: IExercise["free"];
    thumbnail: IExercise["thumbnail"];
  };
}

const Card = styled(Flex)`
  cursor: pointer;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;

  box-shadow: none;

  &:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  transition: box-shadow 150ms linear;
`;

const VideoBox = styled(Flex)<{ imageUrl: string | null }>`
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${({ imageUrl }) =>
    imageUrl
      ? ` background-image: url("${FILE_URL}${imageUrl}");
  background-size: cover;`
      : ` background-image: url("${Thumbnail}");
  background-size: cover; `}

  border-radius: 5px;
  max-width: 180px;
  width: 100%;
  min-width: 100px;
  height: auto;
  aspect-ratio: 16 / 9;
`;

const Title = styled(P)`
  flex-grow: 2;
`;
const PlayButton = styled(Flex)`
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  aspect-ratio: 1 / 1;
  background-color: #fff;
  max-width: 70px;
  width: 100%;
  &.hover {
    background-color: ${({ theme }) => theme.palette.accent.pink.main};
  }
  transition: all 150ms linear;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid ${({ theme }) => theme.palette.accent.pink.main};
  &.hover {
    border-left: 15px solid #fff;
  }
  transition: all 150ms linear;
`;

const WatchButton = styled.span`
  color: ${({ theme }) => theme.palette.accent.pink.main};
  font-weight: 700;
  &.hover {
    text-decoration: underline;
  }
`;

const ExerciseCard = ({ exercise }: IExerciseCard) => {
  const { isLogged } = useAccount();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [openModal, setOpenModa] = useState<boolean>(false);

  const handleClick = () => {
    if (!isLogged) return setOpenModa(true);
    return exercise.isFree
      ? navigate(`/exercises-atlas/${exercise.id}`)
      : navigate("/plans");
  };

  return (
    <>
      <AccessModal
        isOpen={openModal}
        onCancelClick={() => setOpenModa(false)}
        title={
          exercise.isFree
            ? t("exercisesAtlasView.accessModalFree")
            : t("exercisesAtlasView.accessModalPaid")
        }
      />
      <Card
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
      >
        <VideoBox imageUrl={exercise.thumbnail}>
          <PlayButton className={hover ? "hover" : ""}>
            <Triangle className={hover ? "hover" : ""} />
          </PlayButton>
        </VideoBox>
        <Title variant="body" fontWeight={700}>
          {exercise.title}
        </Title>
        <WatchButton className={hover ? "hover" : ""}>
          {exercise.isFree ? t("buttons.watchForFree") : t("buttons.buyAccess")}
        </WatchButton>
      </Card>
    </>
  );
};

export default ExerciseCard;
