import { Pagination, Box } from "components";
import { Key, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TableButtons } from "./TableButtons";
import { getPayments } from "api/payments";
import { IOrder } from "types/payments";
import { formatDate, paymentToOrderConverter } from "utilities/orders";
import {
  Wrapper,
  Table,
  Header,
  Row,
  Th,
  Td,
  Status,
} from "./OrdersTable.styles";

export const OrdersTable = () => {
  const { t } = useTranslation();
  const [actualPage, setActualPage] = useState<number>(1);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await getPayments({ page: actualPage, perPage: 15 });
      const orders = response.data.map((payment) =>
        paymentToOrderConverter(payment),
      );
      setOrders(orders);
      setTotalPages(response.pagination.pages);
    };

    fetchOrders();
  }, [actualPage]);

  const headers = [
    t("ordersView.table.productName"),
    t("ordersView.table.status"),
    t("ordersView.table.date"),
    t("ordersView.table.totalAmount"),
    "",
  ];

  const header = useMemo(() => {
    return (
      <Header>
        {headers.map((e: string, k: Key) => {
          return <Th key={k}>{e}</Th>;
        })}
      </Header>
    );
  }, [headers]);

  return (
    <>
      <Wrapper>
        <Table>
          {header}
          {orders.map((e, k: Key) => {
            return (
              <Row key={k}>
                <Td>{e.productName}</Td>
                <Status isPaid={e.isPaid}>
                  {e.isPaid ? t("ordersView.paid") : t("ordersView.unpaid")}
                </Status>
                <Td>{formatDate(e.date)}</Td>
                <Td>
                  {e.price} {t("zl")}
                </Td>
                <Td>
                  <TableButtons isPaid={e.isPaid} id={e.id.toString()} />
                </Td>
              </Row>
            );
          })}
        </Table>
      </Wrapper>
      {totalPages > 1 && (
        <Pagination
          actualPage={actualPage}
          setActualPage={setActualPage}
          totalPages={totalPages}
        />
      )}
    </>
  );
};
