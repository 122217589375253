import styled from "styled-components";

export const Line = styled.span`
  ${({ theme }) => `
	display: flex;
      background-color: ${theme.palette.accent.light.dark};
      margin: 16px 0;
      width: 100%;
      height: 1px;
  `};
`;
