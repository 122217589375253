import { Flex, Section } from "components";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Banner, BannerItemI } from "./components/Banner";
import { CardsSection, ElementI } from "./components/CardsSection";
import { SecondSection, SecondSectionI } from "./components/SecondSection";
import BannerImage from "assets/images/banner.png";
import { PlansView } from "views/PlansView/PlansView";
import EUsection from "layouts/App/components/EUsection";

export const HomeView: FC = () => {
  const { t } = useTranslation();

  const ElementsData: ElementI[] = [
    {
      title: t("navBar.courses"),
      btnVariant: "dark",
      bgColor: "pink",
      link: "/courses",
    },
    {
      title: t("exercisesAtlasView.exercisesAtlas"),
      btnVariant: "light",
      bgColor: "yellow",
      link: "/exercises-atlas",
    },
    {
      title: t("cardTitle.personalTraining"),
      btnVariant: "dark",
      bgColor: "pink",
      link: "https://treningpersonalnykielce.pl/",
    },
  ];

  const BannerData: BannerItemI[] = [
    {
      markedText: t("homePage.banners.banner1"),
      image: BannerImage,
    },
    {
      normalText: t("homePage.banners.banner2"),
      image: BannerImage,
    },
    {
      markedText: t("homePage.banners.banner3"),
      image: BannerImage,
    },
  ];

  const SecondSectionData: SecondSectionI = {
    title: t("homePage.second.title"),
    bullets: [
      {
        text: t("homePage.second.bullet1"),
        bulletColor: "pink",
      },
      {
        text: t("homePage.second.bullet2"),
        bulletColor: "yellow",
      },
      {
        text: t("homePage.second.bullet3"),
        bulletColor: "yellow",
      },
      {
        text: t("homePage.second.bullet4"),
        bulletColor: "pink",
      },
      {
        text: t("homePage.second.bullet5"),
        bulletColor: "pink",
      },
      {
        text: t("homePage.second.bullet6"),
        bulletColor: "yellow",
      },
    ],
  };

  return (
    <Flex flexDirection="column">
      <Section color="transparent">
        <Banner elements={BannerData} />
        {/*<EUsection />*/}
        <CardsSection elements={ElementsData} />
      </Section>
      <SecondSection
        title={SecondSectionData.title}
        bullets={SecondSectionData.bullets}
      />
      <PlansView />
    </Flex>
  );
};
