import React, { useEffect, useRef } from "react";
import { Box, Flex, Line } from "components";
import { H1, H6 } from "components/Typography/Typography";
import { Button, TextField, TextareaField } from "components/_form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IEditUserData } from "types/forms/user";
import { Flatten } from "types/helper-types";
import { IUser } from "types/user";
import { GridBox, GridBox2 } from "../UserView.styled";
import { Tile, TileContainer, AddPhotoTile } from "./PhotoTile";

interface ProfileFormProps {
  data?: IUser;
  onSubmit: (data: Flatten<IEditUserData>) => void;
  isEditing: boolean;
  setIsEditing: (editing: boolean) => void;
  onAddPhoto: (images: File[]) => Promise<void>;
  onPhotoClick: (url: string) => void;
  handleDeletePhoto: (url: string) => Promise<void>;
}

export const ProfileForm: React.FC<ProfileFormProps> = ({
  data,
  onSubmit,
  isEditing,
  setIsEditing,
  onAddPhoto,
  onPhotoClick,
  handleDeletePhoto,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const renderPhotoTiles = () => {
    if (!data || !data.profile.imagesUrls) {
      return (
        <AddPhotoTile
          key={"addPhoto"}
          onClick={(event) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        />
      );
    }

    const photoTiles = data.profile.imagesUrls.map((imageUrl, index) => (
      <Tile
        key={imageUrl}
        src={imageUrl}
        alt={`User photo ${index + 1}`}
        onClick={onPhotoClick}
        onDelete={handleDeletePhoto}
        isEditing
      />
    ));

    photoTiles.push(
      <AddPhotoTile
        key={"addPhoto"}
        onClick={(event) => {
          event.preventDefault();
          fileInputRef.current?.click();
        }}
      />,
    );

    return photoTiles;
  };
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm<IEditUserData>();

  useEffect(() => {
    if (!data || !data.profile) return;

    const filteredProfile = Object.entries(data.profile).reduce<{
      [key: string]: any;
    }>((filtered, [key, value]) => {
      if (value !== (null || "null")) {
        filtered[key] = value;
      }
      return filtered;
    }, {});

    reset({ ...filteredProfile });
  }, [data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GridBox>
        <Box width="100%">
          <Flex
            flexWrap={"nowrap"}
            flexDirection={"row"}
            gridColumnGap={"15px"}
          >
            <TextField
              label={t("userDetailsView.firstName.label")}
              labelColor="dark"
              placeholder={t("userDetailsView.firstName.placeholder")}
              {...register("firstName")}
              error={t(errors.firstName?.message || "")}
              autoComplete="given-name"
            />
            <TextField
              label={t("userDetailsView.lastName.label")}
              labelColor="dark"
              placeholder={t("userDetailsView.lastName.placeholder")}
              {...register("lastName")}
              error={t(errors.lastName?.message || "")}
              autoComplete="family-name"
            />
          </Flex>
          <TextField
            type="email"
            label={t("userDetailsView.email.label")}
            labelColor="dark"
            placeholder={data?.email}
            autoComplete="email"
            disabled
          />
        </Box>
        <Flex flexDirection="column" width="100%" alignItems="flex-end">
          <H1 variant="h1" color="grey" textAlign="end">
            {data?.profile.firstName} {data?.profile.lastName}
          </H1>
          <H6 variant="h6" fontWeight={400} textAlign="end">
            {data?.email}
          </H6>
        </Flex>
      </GridBox>

      <H6 variant="h6" fontWeight={700} color="grey" mb="-16px">
        {t("userDetailsView.aboutMe")}
      </H6>
      <Line />

      <GridBox2 gap="0px 30px">
        <TextareaField
          label={t("userDetailsView.supplementsConsumption.label")}
          labelColor="dark"
          placeholder={t("userDetailsView.supplementsConsumption.placeholder")}
          {...register("supplementsConsumption")}
          error={t(errors.supplementsConsumption?.message || "")}
        />
        <TextareaField
          label={t("userDetailsView.description.label")}
          labelColor="dark"
          placeholder={t("userDetailsView.description.placeholder")}
          {...register("description")}
          error={t(errors.description?.message || "")}
        />
        <TextareaField
          label={t("userDetailsView.activities.label")}
          labelColor="dark"
          placeholder={t("userDetailsView.activities.placeholder")}
          {...register("activities")}
          error={t(errors.activities?.message || "")}
        />

        <TextField
          label={t("userDetailsView.dimensions.label")}
          labelColor="dark"
          placeholder={t("userDetailsView.dimensions.placeholder")}
          {...register("dimensions")}
          error={t(errors.dimensions?.message || "")}
        />
        <TextField
          label={t("userDetailsView.weight.label")}
          labelColor="dark"
          placeholder={t("userDetailsView.weight.placeholder")}
          {...register("weight")}
          error={t(errors.weight?.message || "")}
        />
        <TextField
          label={t("userDetailsView.waterConsumption.label")}
          labelColor="dark"
          placeholder={t("userDetailsView.waterConsumption.placeholder")}
          {...register("waterConsumption")}
          error={t(errors.waterConsumption?.message || "")}
        />

        <TextField
          label={t("userDetailsView.caloriesConsumption.label")}
          labelColor="dark"
          placeholder={t("userDetailsView.caloriesConsumption.placeholder")}
          {...register("caloriesConsumption")}
          error={t(errors.caloriesConsumption?.message || "")}
        />
      </GridBox2>

      <H6 variant="h6" fontWeight={700} color="grey" mb="-16px">
        {t("userDetailsView.photos.label")}
      </H6>
      <Line />

      <Flex flexDirection="column">
        <TileContainer>{renderPhotoTiles()}</TileContainer>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInputRef}
          multiple
          onChange={(event) => {
            if (event.target.files) {
              onAddPhoto(Array.from(event.target.files));
            }
          }}
        />
      </Flex>

      {isEditing && (
        <>
          <Flex gap="20px" width="100%" justifyContent="center" mt="16px">
            <Button
              textOnly
              variant="secondary"
              label={t("buttons.cancel")}
              onClick={() => setIsEditing(false)}
            />
            <Button
              type="submit"
              variant="secondary"
              label={t("buttons.saveChanges")}
              disabled={!isDirty}
            />
          </Flex>
        </>
      )}
    </form>
  );
};
