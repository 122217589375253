import { FC } from "react";
import { ReactComponent as Bullet } from "assets/icons/ico-bullet.svg";
import styled from "styled-components";
import { Box, Flex, Section } from "components";
import { device, theme } from "styles/theme";
import { H2, P } from "components/Typography/Typography";

export interface SecondSectionI {
  title: string;
  bullets: BulletI[];
}

interface BulletI {
  text: string;
  bulletColor: BulletColor;
}

type BulletColor = "yellow" | "pink";

const { palette } = theme;

const bulletColorMap = {
  pink: palette.accent.pink.main,
  yellow: palette.accent.yellow.main,
};

const StyledWrapper = styled(Section)`
  padding-block: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 30px;
  color: ${palette.text.secondary};
  word-break: break-all;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
    row-gap: 30px;
    justify-items: center;
  }
`;

const StyledBulletWrapper = styled(StyledWrapper)`
  padding: 60px 0px;
  column-gap: 68px;
  row-gap: 30px;
`;

const StyledBullet = styled(Bullet)<{ $bulletColor: BulletColor }>`
  ${({ $bulletColor }) => `
    stroke: ${bulletColorMap[$bulletColor]}
  `}
`;

export const SecondSection: FC<SecondSectionI> = ({ title, bullets }) => {
  return (
    <StyledWrapper color="transparent">
      <H2 variant={"h2"} style={{ wordBreak: "normal" }}>
        {title}
      </H2>
      <StyledBulletWrapper color="transparent">
        {bullets.map((e, k) => {
          return (
            <Flex
              flexDirection="row"
              flexWrap="nowrap"
              gridColumnGap={25}
              alignItems={"center"}
              key={k}
            >
              <Box>
                <StyledBullet $bulletColor={e.bulletColor} />
              </Box>

              <P variant="body" style={{ wordBreak: "normal" }}>
                {e.text}
              </P>
            </Flex>
          );
        })}
      </StyledBulletWrapper>
    </StyledWrapper>
  );
};
