import React from "react";
import styled from "styled-components";
import { Box } from "components";
import { Flex } from "components/Flex";
import { H6, P } from "components/Typography/Typography";
import { useDropdown } from "hooks/useDropdown";
import { ReactComponent as ChevronDown } from "assets/icons/ico-chevronDown.svg";
import { ReactComponent as ChevronUp } from "assets/icons/ico-chevronUp.svg";
import { useAccount } from "hooks/useAccount";

export interface IDropdown {
  items: Array<{
    label: string;
    onClick: () => void;
  }>;
  children?: React.ReactNode;
}

const Root = styled.div`
  position: relative;
`;

const WrapperChildren = styled(Flex)`
  cursor: pointer;
`;

const WrapperDropdown = styled.div`
  right: 0;
  position: absolute;
  top: 100%;
  margin-top: 16px;
  min-width: 180px;
  z-index: 1000;
`;

const Title = styled(Box)`
  padding: 5px 15px;
`;

const Container = styled(Box)`
  position: relative;
  flex-direction: column;
  border-radius: 5px;
  border-top-right-radius: 0px;
  ${({ theme }) => `background-color: ${theme.palette.accent.yellow.main}`}
`;

const WhiteSection = styled(Box)`
  ${({ theme }) => `background-color: ${theme.palette.neutral.white};`}
  border-radius: 0px 0px 5px 5px;
  padding-block: 5px;
`;

const Arrow = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid ${({ theme }) => theme.palette.accent.yellow.main};
`;

const StyledTypography = styled(P)`
  padding: 5px 15px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

export const Dropdown: React.FC<IDropdown> = ({ items, children }) => {
  const { buttonRef, optionsRef, isVisible, setIsVisible } = useDropdown(false);

  const { userData } = useAccount();
  const handleClick = (onClick: () => any) => () => {
    onClick();
    setIsVisible(false);
  };

  return (
    <Root>
      <WrapperChildren
        ref={buttonRef}
        role="button"
        aria-hidden="true"
        alignItems="center"
      >
        {children}
        <Box ml="10px">{isVisible ? <ChevronUp /> : <ChevronDown />}</Box>
      </WrapperChildren>

      {isVisible && (
        <WrapperDropdown ref={optionsRef}>
          <Container>
            <Arrow />
            <Title>
              <H6 variant="h6">{`${userData.firstName} ${userData.lastName}`}</H6>
              <P variant="body2">{userData.email}</P>
            </Title>
            <WhiteSection>
              {items.map(({ label, onClick }) => (
                <div
                  key={label}
                  onClick={handleClick(onClick)}
                  aria-hidden="true"
                  role="button"
                >
                  <StyledTypography variant="body">{label}</StyledTypography>
                </div>
              ))}
            </WhiteSection>
          </Container>
        </WrapperDropdown>
      )}
    </Root>
  );
};
