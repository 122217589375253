import React from "react";
import { Footer } from "layouts/App/components/Footer";
import { Wrapper } from "./AppLayout.styled";
import { Navbar } from "../App/components/Navbar";
import EUsection from "./components/EUsection";

export const AppLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Wrapper>
      <EUsection />
      <Navbar />
      {children}
      <Footer />
    </Wrapper>
  );
};
