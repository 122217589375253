import styled from "styled-components";
import { device } from "styles/theme";
import { theme } from "styles/theme";
import Dots from "assets/images/dots.png";
import { Box } from "components/Box";

interface ISection {
  color: "navbar" | "dark" | "light" | "footer" | "transparent";
  dots?: boolean;
}

const { palette } = theme;

const backgroundColorVariantMap = {
  navbar: palette.background.navbar,
  dark: palette.background.dark,
  light: palette.background.light,
  footer: palette.accent.yellow.main,
  transparent: "transparent",
};

export const Section = styled(Box)<ISection>`
  padding: 0px 200px;

  @media ${device.desktop} {
    padding: 0px 100px;
  }

  @media ${device.tablet} {
    padding: 0px 50px;
  }

  @media ${device.mobile} {
    padding: 0px 25px;
  }

  ${({ color }) => `
    background-color: ${backgroundColorVariantMap[color]};
  `}
  ${({ dots }) =>
    dots ? `background-image: url(${Dots}); background-size: cover;` : ""}
`;
