import { getSubscriptions } from "api/subscriptions";
import { Box, Flex, Loader, Section } from "components";
import { H4 } from "components/Typography/Typography";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ISubscription } from "types/subscriptions";
import { OfferCard } from "./components/OfferCard";

export interface IPlansView {
  normalTitle: string;
  markedTitle: string;
  partner?: string;
}

const StyledWrapper = styled(Section)`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-height: 40px;
  padding-block: 60px;
  text-align: center;
  gap: 60px;
`;

const StyledPartnersWrapper = styled(Flex)`
  flex-flow: row nowrap;
  justify-content: space-evenly;
  width: 100%;
  text-transform: uppercase;
`;

const CardsContainer = styled(Flex)`
  flex-flow: row wrap;
  gap: 45px;
  justify-content: center;
`;

export const PlansView: FC = () => {
  const [plansData, setPlansData] = useState<ISubscription[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const data: IPlansView = {
    normalTitle: "Wybierz produkt, który spełnia",
    markedTitle: "Twoje potrzeby",
    partner: "superdieta.pl",
  };

  const loadPlansData = async () => {
    setIsLoading(true);
    try {
      const { data } = await getSubscriptions();
      if (!data) return;
      setPlansData(data);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadPlansData();
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      <StyledWrapper color="light">
        <Flex flexDirection="column" width="100%" gap="10px">
          <H4 variant={"h4"}>{data.normalTitle}</H4>
          <H4 variant={"h4"} color="pink">
            {data.markedTitle}
          </H4>
        </Flex>
        <CardsContainer>
          {plansData.map((e, k) => {
            return (
              <OfferCard
                key={e.id}
                variant={(k + 1) % 2 ? "light" : "yellow"}
                data={e}
              />
            );
          })}
        </CardsContainer>
        <StyledPartnersWrapper>
          <H4 variant="h4" fontWeight={400}>
            {data.partner}
          </H4>
        </StyledPartnersWrapper>
      </StyledWrapper>
    </>
  );
};
