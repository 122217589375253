import { getSubscription } from "api/subscriptions";
import { Flex, Typography } from "components";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ISubscription } from "types/subscriptions";
import { IBuyElement } from "./BuyView";

export const PlanDetails = ({ id }: IBuyElement) => {
  const { t } = useTranslation();
  const [displayData, setDisplayData] = useState<ISubscription>();

  const loadData = async () => {
    const { data } = await getSubscription(id ?? "");
    setDisplayData(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Typography.H5 variant="h5" color="secondary">
        {displayData?.title}
      </Typography.H5>
      <Flex alignItems="center" gridColumnGap={"10px"}>
        <Typography.H6 variant="h6" color="secondary">
          {t("buyView.price")}
        </Typography.H6>
        <Typography.H5 variant="h5" color="pink">
          {Number(displayData?.price).toFixed(2)} {t("zl")}
        </Typography.H5>
      </Flex>
    </>
  );
};
