import { yupResolver } from "@hookform/resolvers/yup";
import { getRegulation } from "api/regulations";
import { Box, Flex, Loader, Typography } from "components";
import { Button, Checkbox, TextareaField, TextField } from "components/_form";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { device } from "styles/theme";
import { IContact } from "types/forms/contact";
import { IRegulation } from "types/regulations";
import { contactSchema } from "validation/authSchemas";
import { useAccount } from "hooks/useAccount";
import { createContactMessage } from "api/contactMessages";
import { toast } from "react-toastify";

interface IContactForm {
  className: string;
}

const FormWrapper = styled(Flex)`
  gap: 20px;
  width: 100%;
  max-width: 480px;
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const ContactForm = ({ className }: IContactForm) => {
  const { userData } = useAccount();
  const [regulation, setRegulation] = useState<IRegulation>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    register,
    formState,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IContact>({
    mode: "onChange",
    defaultValues: {
      email: userData.email,
      title: "",
      message: "",
      regulation: false,
    },
    resolver: yupResolver(contactSchema),
  });

  const loadRegulations = async () => {
    const { data } = await getRegulation("1");
    setRegulation(data);
  };

  useEffect(() => {
    loadRegulations();
  }, []);

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    try {
      const response = await createContactMessage(data);
      toast.success("Wiadomość wysłana");
      reset();
    } catch (e: any) {
      toast.error("Wiadomość niewysłana");
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <>
      <Loader isLoading={isLoading} />
      <FormWrapper flexDirection="column" className={className}>
        <Typography.H4 variant="h4">{t("contactView.writeToUs")}</Typography.H4>
        <Typography.P variant="body">{t("contactView.fillForm")}</Typography.P>
        <form onSubmit={onSubmit}>
          <TextField
            labelColor="dark"
            type="email"
            label={t("form.email.label")}
            placeholder={t("form.email.placeholder")}
            {...register("email")}
            error={t(errors.email?.message || "")}
          />
          <TextField
            labelColor="dark"
            type="text"
            label={t("form.title.label")}
            placeholder={t("form.title.placeholder")}
            {...register("title")}
            error={t(errors.title?.message || "")}
          />
          <TextareaField
            labelColor="dark"
            label={t("form.message.label")}
            placeholder={t("form.message.placeholder")}
            {...register("message")}
            error={t(errors.message?.message || "")}
            rows={5}
          />
          <Controller
            control={control}
            name="regulation"
            render={({ field: { onChange } }) => (
              <Flex my={2}>
                <Checkbox
                  required={regulation?.required}
                  color="dark"
                  name="regulation"
                  onChange={(e) => onChange(e.target.checked)}
                  label={t("form.accepting")}
                  labelLink={{
                    text: regulation?.name,
                    href: `/info${regulation?.link}`,
                  }}
                />
              </Flex>
            )}
          />
          <Box mt={3}>
            <Button
              type="submit"
              disabled={!formState.isValid}
              variant="secondary"
            >
              {t("buttons.send")}
            </Button>
          </Box>
        </form>
      </FormWrapper>
    </>
  );
};
