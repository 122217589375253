import { FC, useEffect, useState } from "react";
import { Box, Flex, Loader, Section } from "components";
import { Button, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordSchema } from "validation/authSchemas";
import { IChangePassword } from "types/forms/auth";
import { setNewPassword } from "../api/authApi";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const Wrapper = styled(Flex)`
  max-width: 480px;
`;

export const NewPasswordView: FC = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = searchParams.get("token");

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IChangePassword["user"]>({
    mode: "onChange",
    defaultValues: {
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver(changePasswordSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      if (!token) return;
      const response = await setNewPassword({
        user: { ...data, reset_password_token: token },
      });
      if (!response) return;
      toast.success(t("passwordChangedSuccess"));
      navigate("/signin");
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    !token && navigate("/");
  }, [token]);

  return (
    <Section color="light">
      <Loader isLoading={isLoading} />
      <Wrapper
        my={4}
        justifyContent="center"
        flexWrap="wrap"
        flexDirection="column"
      >
        <form onSubmit={onSubmit}>
          <TextField
            type="password"
            labelColor="dark"
            label={t("form.password.label")}
            placeholder={t("form.password.placeholder")}
            {...register("password")}
            error={t(errors.password?.message || "")}
            autoComplete="new-password"
          />
          <TextField
            type="password"
            labelColor="dark"
            label={t("form.password_confirmation.label")}
            placeholder={t("form.password_confirmation.placeholder")}
            {...register("password_confirmation")}
            error={t(errors.password_confirmation?.message || "")}
            autoComplete="new-password"
          />
          <TextField type="hidden" {...register("reset_password_token")} />
          <Box my={2}>
            <Button fullWidth type="submit" disabled={!formState.isValid}>
              {t("auth.set_new_password")}
            </Button>
          </Box>
        </form>
      </Wrapper>
    </Section>
  );
};
