import {
  Box,
  Flex,
  Section,
  Typography,
  SectionHeader,
  Loader,
} from "components";
import { TextField } from "components/_form";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "assets/icons/white-search.svg";
import { useTranslation } from "react-i18next";
import ExerciseCard from "./components/ExerciseCard";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IBodyPart, IExercise } from "types/exercises";
import { H5, P } from "components/Typography/Typography";
import PersonFront from "./components/PersonFront";
import PersonBack from "./components/PersonBack";
import { getBodyParts } from "api/bodyParts";
import { device } from "styles/theme";
import InfoCard from "./components/InfoCard";

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0px, 1fr);
  gap: 20px;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
  }
`;

const MusclePartsContainer = styled(Flex)`
  width: fit-content;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
`;

const DarkHeader = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #000;
`;

const ExercisesSection = styled.div`
  @media ${device.laptop} {
    order: -1;
  }
`;

const bodyPartsTitle = {
  chest: "exercisesAtlasView.trainingType.chest",
  shoulders: "exercisesAtlasView.trainingType.shoulders",
  biceps: "exercisesAtlasView.trainingType.biceps",
  triceps: "exercisesAtlasView.trainingType.triceps",
  back: "exercisesAtlasView.trainingType.back",
  quadriceps: "exercisesAtlasView.trainingType.quadriceps",
  hamstrings: "exercisesAtlasView.trainingType.hamstrings",
  calves: "exercisesAtlasView.trainingType.calves",
  buttocks: "exercisesAtlasView.trainingType.buttocks",
  stomach: "exercisesAtlasView.trainingType.stomach",
};

export const ExercisesAtlas = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<IBodyPart[]>([]);
  const [selectedBodyPart, setSelectedBodyPart] = useState<string>("");
  const [exercises, setExecises] = useState<IExercise[]>([]);
  const { t } = useTranslation();

  const fetchBodyParts = async () => {
    setIsLoading(true);
    try {
      const result = await getBodyParts();
      if (!result) return;
      setData(result.data);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBodyParts();
  }, []);

  useEffect(() => {
    if (!!data.length && selectedBodyPart) {
      const el = data.find((e) => e.name === selectedBodyPart);
      if (!!el) {
        setExecises(el.exercises);
      }
    }
  }, [data, selectedBodyPart]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <SectionHeader>
        <Typography.H3 variant="h3" color="primary">
          {t("exercisesAtlasView.exercisesAtlas")}
        </Typography.H3>

        <Box width={["100%", "40%"]}>
          <TextField placeholder={`${t("search")}...`} icon={<SearchIcon />} />
        </Box>
      </SectionHeader>
      <Section color="light" style={{ paddingBlock: "30px" }}>
        <Grid>
          <MusclePartsContainer>
            <DarkHeader>
              <P variant="body" color="secondary" textAlign="center">
                {t("exercisesAtlasView.chooseMusclePart")}
              </P>
            </DarkHeader>
            <Flex
              justifyContent="center"
              gap="20px"
              p="20px"
              width="100%"
              flexWrap="wrap"
            >
              <PersonFront
                muscleGroup={selectedBodyPart}
                setMuscleGroup={setSelectedBodyPart}
              />
              <PersonBack
                muscleGroup={selectedBodyPart}
                setMuscleGroup={setSelectedBodyPart}
              />
            </Flex>
          </MusclePartsContainer>

          <ExercisesSection>
            {!!selectedBodyPart ? (
              <Flex flexDirection="column" gap="10px">
                <H5 variant="h5">
                  {t("exercisesAtlasView.training")}{" "}
                  {t(
                    `${
                      bodyPartsTitle[
                        selectedBodyPart as keyof typeof bodyPartsTitle
                      ]
                    }`,
                  )}
                </H5>
                <P variant="body">{t("exercisesAtlasView.chooseTraining")}:</P>
                {!!exercises.length ? (
                  exercises.map((e) => {
                    return (
                      <ExerciseCard
                        key={e.id}
                        exercise={{
                          id: e.id,
                          thumbnail: e.thumbnail,
                          title: e.title,
                          isFree: e.free,
                        }}
                      />
                    );
                  })
                ) : (
                  <P variant="body2" color="grey">
                    {t("exercisesAtlasView.noExercises")}
                  </P>
                )}
                <P variant="body2">{t("exercisesAtlasView.extraInfo")}</P>
              </Flex>
            ) : (
              <InfoCard />
            )}
          </ExercisesSection>
        </Grid>
      </Section>
    </>
  );
};
