import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

export interface IFieldLabel {
  error?: string;
  disabled?: InputHTMLAttributes<HTMLInputElement>["disabled"];
  color?: "light" | "dark";
}

export const FieldLabel = styled.label<IFieldLabel>`
  ${({ theme, disabled, error, color }) => `
    display: inline-block;
    color: ${
      color === "dark"
        ? theme.palette.text.primary
        : theme.palette.text.secondary
    };
    width: 95%;    
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    ${error && `color: ${theme.palette.accent.red}`};
    ${
      disabled &&
      `color: ${
        color === "dark"
          ? rgba(theme.palette.text.primary, 0.4)
          : rgba(theme.palette.text.secondary, 0.4)
      }`
    };
  `}
`;
