import { Flex, Section } from "components";
import { H1 } from "components/Typography/Typography";
import { Button } from "components/_form";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const NotFoundView = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Section color="light">
      <Flex
        py="60px"
        width="100%"
        flexDirection="column"
        alignItems="center"
        gap="30px"
      >
        <H1 variant="h1">{t("notFoundView.title")}</H1>
        <Button variant="secondary" onClick={() => navigate("/")}>
          {t("buttons.backToHomeView")}
        </Button>
      </Flex>
    </Section>
  );
};
