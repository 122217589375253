import { ISubscription, ISubscriptionResponse } from "types/subscriptions";
import request from "./index";

export const getSubscriptions = () =>
  request<ISubscriptionResponse>({
    options: {
      url: "/subscriptions",
      method: "get",
    },
  });

export const getSubscription = (id: number | string) =>
  request<{ data: ISubscription }>({
    options: {
      url: `/subscriptions/${id}`,
      method: "get",
    },
  });
