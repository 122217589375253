import React from "react";
import { IUser } from "types/user";
import { GridBox, GridBox2 } from "../UserView.styled";
import { H1, H6, P } from "components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { Flex, Line } from "components";
import { Tile, TileContainer } from "./PhotoTile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPills,
  faNoteSticky,
  faDumbbell,
  faGlassWater,
  faWeightScale,
  faRuler,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";

interface ProfileDetailsProps {
  data?: IUser;
  onPhotoClick: (url: string) => void;
}

export const ProfileDetails: React.FC<ProfileDetailsProps> = ({
  data,
  onPhotoClick,
}) => {
  const { t } = useTranslation();

  const renderPhotoTiles = () => {
    if (!data || !data.profile.imagesUrls) {
      return <></>;
    }

    const photoTiles = data.profile.imagesUrls.map((imageUrl, index) => (
      <Tile
        key={imageUrl}
        src={imageUrl}
        alt={`User photo ${index + 1}`}
        onClick={onPhotoClick}
      />
    ));

    return photoTiles;
  };

  return (
    <>
      <GridBox>
        <div />
        <Flex flexDirection="column" width="100%" alignItems="flex-end">
          <H1 variant="h1" color="grey" textAlign="end">
            {data?.profile.firstName} {data?.profile.lastName}
          </H1>
          <H6 variant="h6" fontWeight={400} textAlign="end">
            {data?.email}
          </H6>
        </Flex>
      </GridBox>

      <H6 variant="h6" fontWeight={700} color="grey" mb="-16px">
        {t("userDetailsView.aboutMe")}
      </H6>
      <Line />

      <GridBox2 mb={5} gap="30px">
        <Flex flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <FontAwesomeIcon icon={faPills} size="xl" />
            <P variant="body3" fontWeight={700}>
              {t("userDetailsView.supplementsConsumption.label")}
            </P>
          </Flex>
          <P variant="body3">{data?.profile.supplementsConsumption}</P>
        </Flex>
        <Flex flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <FontAwesomeIcon icon={faNoteSticky} size="xl" />
            <P variant="body3" fontWeight={700}>
              {t("userDetailsView.description.label")}
            </P>
          </Flex>
          <P variant="body3">{data?.profile.description}</P>
        </Flex>
        <Flex flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <FontAwesomeIcon icon={faDumbbell} size="xl" />
            <P variant="body3" fontWeight={700}>
              {t("userDetailsView.activities.label")}
            </P>
          </Flex>
          <P variant="body3">{data?.profile.activities}</P>
        </Flex>

        <Flex flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <FontAwesomeIcon icon={faRuler} size="xl" />
            <P variant="body3" fontWeight={700}>
              {t("userDetailsView.dimensions.label")}
            </P>
          </Flex>
          <P variant="body3">{data?.profile.dimensions}</P>
        </Flex>
        <Flex flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <FontAwesomeIcon icon={faWeightScale} size="xl" />
            <P variant="body3" fontWeight={700} mr={2}>
              {t("userDetailsView.weight.label")}
            </P>
          </Flex>
          <P variant="body3">{data?.profile.weight}</P>
        </Flex>
        <Flex flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <FontAwesomeIcon icon={faGlassWater} size="xl" />
            <P variant="body3" fontWeight={700} mr={2}>
              {t("userDetailsView.waterConsumption.label")}
            </P>
          </Flex>
          <P variant="body3">{data?.profile.waterConsumption}</P>
        </Flex>

        <Flex flexDirection="column">
          <Flex gap="10px" alignItems="center">
            <FontAwesomeIcon icon={faUtensils} size="xl" />
            <P variant="body3" fontWeight={700} mr={2}>
              {t("userDetailsView.caloriesConsumption.label")}
            </P>
          </Flex>
          <P variant="body3">{data?.profile.caloriesConsumption}</P>
        </Flex>
      </GridBox2>

      <H6 variant="h6" fontWeight={700} color="grey" mb="-16px">
        {t("userDetailsView.photos.label")}
      </H6>
      <Line />

      <Flex flexDirection="column">
        <TileContainer>{renderPhotoTiles()}</TileContainer>{" "}
      </Flex>
    </>
  );
};
