import { Section } from "components/Section/Section";
import styled from "styled-components";
import React from "react";

const StyledHeader = styled(Section)`
  background-color: ${({ theme }) => theme.palette.neutral.white};
  padding-block: 20px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
`;
interface ISectionHeader {
  children: React.ReactNode;
}

export const SectionHeader = ({ children }: ISectionHeader) => {
  return <StyledHeader color="light">{children}</StyledHeader>;
};
