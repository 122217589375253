import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
});

export const registerSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "passDiffErr"),
  first_name: yup.string(),
  last_name: yup.string(),
});

export const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, "passMinVal")
    .max(18, "passMaxVal")
    .required("passwordRequired"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password")], "passDiffErr"),
});

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
});

export const buySchema = yup.object().shape({
  first_name: yup.string().required("validation.fieldIsRequired"),
  last_name: yup.string().required("validation.fieldIsRequired"),
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
});

export const contactSchema = yup.object().shape({
  email: yup
    .string()
    .required("emailRequired")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "badEmailFormat",
    ),
  title: yup.string().required("validation.fieldIsRequired"),
  message: yup.string().required("validation.fieldIsRequired"),
  regulation: yup.boolean().oneOf([true], "validation.acceptRules"),
});
