import { Flex, Modal, Typography } from "components";
import { IModal } from "components/Modal/Modal";
import { Button } from "components/_form";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

interface IAccessModal extends Pick<IModal, "isOpen" | "onCancelClick"> {
  title?: string;
}

const Wrapper = styled(Flex)`
  flex-flow: column nowrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
`;

const SingUpWrapper = styled(Flex)`
  flex-flow: row wrap;
  gap: 10px;
  align-items: center;
`;

export const AccessModal = ({ isOpen, onCancelClick, title }: IAccessModal) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} onCancelClick={onCancelClick} disableBackdropClick>
      <Wrapper>
        <Typography.H5 variant="h5" color="primary">
          {title}
        </Typography.H5>
        <Button
          variant="secondary"
          onClick={() => {
            onCancelClick();
            navigate("/login");
          }}
        >
          {t("buttons.signIn")}
        </Button>
        <SingUpWrapper>
          <Typography.P variant="body">{t("accountless")}</Typography.P>
          <Button
            variant="secondary"
            textOnly
            onClick={() => {
              onCancelClick();
              navigate("/register");
            }}
          >
            {t("buttons.singUp")}
          </Button>
        </SingUpWrapper>
      </Wrapper>
    </Modal>
  );
};
