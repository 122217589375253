import React, { useEffect, useState } from "react";
import { Flex } from "components/Flex";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface IMenuItem {
  path: string;
  label: string;
  private: boolean;
  type?: string;
}
export interface IMenu {
  items?: Array<IMenuItem>;
  onMenuIconClick: () => void;
  view?: "horizontal" | "vertical";
}
interface IListItem {
  active?: boolean;
}

const NavigationLink = styled(NavLink)`
  text-align: center;
  ${({ theme }) => `
    display: block;
    color: ${theme.palette.text.secondary};
    text-decoration: none;
    :focus-visible {    
        color: ${theme.palette.accent.pink.light};   
      }
  `};
`;
const ListItem = styled(Flex)<IListItem>`
  text-transform: capitalize;
  ${({ theme, active }) => `
  height: max-content;
  color: ${active && theme.palette.accent.pink.main};    
  font-weight: 400;
      :hover {    
        color: ${theme.palette.accent.pink.light};   
      }
      :active {    
        color: ${theme.palette.accent.pink.dark};   
      }
  `}
  transition: all linear 100ms;
`;

export const Menu = ({
  items,
  onMenuIconClick,
  view = "horizontal",
}: IMenu) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [activePath, setIsActivePath] = useState<string>(pathname || "");

  const handleOnClick = (path: string) => {
    onMenuIconClick();
    setIsActivePath(path);
  };

  useEffect(() => {
    setIsActivePath(pathname);
  }, [pathname]);

  const renderItems = () => {
    if (!items) return;
    return items.map(({ path, label }) => {
      const isItemSelected = activePath.includes(path);

      return (
        <NavigationLink to={path} key={label}>
          <ListItem
            active={isItemSelected}
            px={3}
            alignItems="center"
            onClick={() => handleOnClick(path)}
          >
            {t(label)}
          </ListItem>
        </NavigationLink>
      );
    });
  };

  return (
    <Flex
      width="100%"
      flexDirection={view === "horizontal" ? "column" : "row"}
      alignItems="center"
    >
      {renderItems()}
    </Flex>
  );
};
