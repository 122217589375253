import React from "react";
import styled from "styled-components";
import { Flex, Box, Link, Section, Typography } from "components";
import { ReactComponent as YouTubeIcon } from "assets/icons/youtube.svg";
import { ReactComponent as TiktokIcon } from "assets/icons/tiktok.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/linkedIn.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/fbook.svg";
import { H6, P } from "components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { device } from "styles/theme";
import { addressCoords, contactData } from "constants/contactData";
import { rgba } from "utilities/rgba";
import { socialMediaLinks } from "constants/socialMediaLinks";
import { TextField } from "components/_form";
import { Map } from "components/Map";
import { ReactComponent as ArrowIcon } from "assets/icons/newsletter-arrow.svg";
import { useAccount } from "hooks/useAccount";
import EUsection from "./EUsection";

const Wrapper = styled(Section)`
  display: flex;
  flex-flow: column nowrap;
  padding-block: 60px;
  width: 100%;
  gap: 25px;
`;

const ItemsWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 20px;

  @media ${device.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ItemTitle = styled(H6)`
  text-transform: capitalize;
  margin-bottom: 35px;
  @media ${device.laptop} {
    margin-bottom: 20px;
  }
`;

const Item = styled(Flex)`
  flex-flow: column;
  width: 100%;
`;

const Links = styled(Item)`
  gap: 15px;
  justify-content: flex-start;
`;

const Calendar = styled(Item)`
  justify-content: flex-start;
`;

const Day = styled(Flex)`
  text-transform: capitalize;
  flex-flow: row wrap;
  justify-content: space-between;
  ${({ theme }) =>
    `border-bottom: 1px solid ${rgba(theme.palette.text.secondary, 0.2)};`}
  :last-child {
    border-bottom: unset;
  }
`;

const Icons = styled(Flex)`
  justify-content: flex-start;
  gap: 20px;
  svg {
    cursor: pointer;
    height: 30px;
    width: auto;
    fill: ${({ theme }) => theme.palette.text.primary};
  }
`;

export const Footer = () => {
  const { t } = useTranslation();
  const { userData } = useAccount();

  const openingHoursData = [
    {
      day: "poniedziałek",
      hours: "8:00 - 16:00",
      isWeekend: false,
    },
    {
      day: "wtorek",
      hours: "8:00 - 16:00",
      isWeekend: false,
    },
    {
      day: "środa",
      hours: "8:00 - 16:00",
      isWeekend: false,
    },
    {
      day: "czwartek",
      hours: "8:00 - 16:00",
      isWeekend: false,
    },
    {
      day: "piątek",
      hours: "8:00 - 16:00",
      isWeekend: false,
    },
    {
      day: "sobota",
      hours: "zamknięte",
      isWeekend: true,
    },
    {
      day: "niedziela",
      hours: "zamknięte",
      isWeekend: true,
    },
  ];

  return (
    <Wrapper color="footer">
      <ItemsWrapper>
        <Item>
          <ItemTitle variant="h6">Kontakt</ItemTitle>
          <Links>
            <Link href={`tel:${contactData.phoneNumber}`}>
              Tel. {contactData.phoneNumber}
            </Link>
            <Link href={`mailto:${contactData.emailAdress}`}>
              {contactData.emailAdress}
            </Link>
            <Link to={"/info/polityka-prywatnosci"} variant={"body2"}>
              {t("privacyPolicy")}
            </Link>
            <Link to={"/info/regulamin"} variant={"body2"}>
              {t("regulamin")}
            </Link>
            <P color="grey" variant="helperText">
              {t("footer.copyright")}
            </P>
          </Links>
        </Item>
        <Item>
          <ItemTitle variant="h6">{t("footer.openingHours")}</ItemTitle>
          <Calendar>
            {openingHoursData.map((e, k) => {
              return (
                <Day key={k}>
                  <P variant="body2" color={e.isWeekend ? "pink" : "primary"}>
                    {e.day}
                  </P>
                  <P
                    variant="body2"
                    color={e.isWeekend ? "pink" : "primary"}
                    fontWeight={700}
                  >
                    {e.hours}
                  </P>
                </Day>
              );
            })}
          </Calendar>
        </Item>
        <Item>
          <ItemTitle variant="h6">{t("footer.findMeOn")}</ItemTitle>
          <Icons>
            <Link target="_blank" href={socialMediaLinks.facebook}>
              <FacebookIcon />
            </Link>
            <Link target="_blank" href={socialMediaLinks.linkedin}>
              <LinkedInIcon />
            </Link>
            <Link target="_blank" href={socialMediaLinks.tiktok}>
              <TiktokIcon />
            </Link>
            <Link target="_blank" href={socialMediaLinks.youtube}>
              <YouTubeIcon />
            </Link>
          </Icons>
        </Item>
        <Item>
          <ItemTitle variant="h6">{t("footer.newsletter")}</ItemTitle>
          <Typography.P variant="body">
            {t("footer.signUpToNewsletter")}
          </Typography.P>
          <TextField
            placeholder={t("form.email.placeholder")}
            icon={<ArrowIcon />}
            onIconClick={() => {}}
            autoComplete="email"
            value={userData.email}
            onChange={() => {}}
          />
        </Item>
      </ItemsWrapper>
      {/* <EUsection /> */}
      <Map lat={addressCoords.lat} lng={addressCoords.lng} />
    </Wrapper>
  );
};
