import styled from "styled-components";
import { Flex } from "components";
import Background from "assets/images/background.png";

export const Wrapper = styled(Flex)`
  flex-flow: column nowrap;
  ${({ theme }) => `
    background-color: ${theme.palette.background.dark};
    background-image: url("${Background}");
    background-repeat: no-repeat;
    background-size: 100%;
  `}
`;
