import { Box, Typography } from "components";
import React from "react";
import styled from "styled-components";
import AboutUsImage from "assets/images/aboutUsImage.png";
import { device } from "styles/theme";
import {
  BackgroundImage,
  BottomSection,
  Header,
  HeaderContainer,
  HeaderShadow,
  UpperSection,
  Text,
  Image,
} from "./components/AboutContractView.styled";
import { useTranslation } from "react-i18next";
import AboutPhoto1 from "assets/images/aboutAsPhoto1.png";
import AboutPhoto2 from "assets/images/aboutAsPhoto2.png";

const Grid = styled(Box)`
  .imgFst {
    grid-area: imgFst;
  }
  .imgSec {
    grid-area: imgSec;
  }
  .textFst {
    grid-area: textFst;
  }
  .textSec {
    grid-area: textSec;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "imgFst textFst" "textSec imgSec";

  gap: 120px;
  @media ${device.laptop} {
    grid-template-columns: 1fr;
    grid-template-areas: "imgFst " "textFst" " imgSec" "textSec";
    gap: 60px;
  }
  justify-items: center;
  align-items: center;
  padding-block: 40px;
`;

export const AboutUsView = () => {
  const { t } = useTranslation();
  return (
    <>
      <BackgroundImage imageUrl={AboutUsImage}>
        <UpperSection color="transparent">
          <HeaderContainer color="pink">
            <Header>{t("aboutUsView.header")}</Header>
            <HeaderShadow>{t("aboutUsView.header")}</HeaderShadow>
          </HeaderContainer>
        </UpperSection>
      </BackgroundImage>
      <BottomSection color="light">
        <Grid>
          <Image src={AboutPhoto1} className="imgFst" />
          <Image src={AboutPhoto2} className="imgSec" />
          <Text className="textFst">
            <Typography.H5 variant="h5">
              Witaj na naszej platformie!
            </Typography.H5>
            <Typography.P variant="body">
              Cieszymy się, że tu jesteś. Jeśli szukasz profesjonalnego wsparcia
              w osiągnięciu swoich treningowych celów, jesteś we właściwym
              miejscu.
            </Typography.P>
            <Typography.P variant="body">
              Zapewniamy Ci kompleksową opiekę i wsparcie treningowe, bez
              względu na to, gdzie się znajdujesz. Bez wychodzenia z domu,
              będziesz mógł/mogła uczestniczyć w ćwiczeniach online, otrzymywać
              instrukcje i korygowanie formy treningowej.
            </Typography.P>
            <Typography.P variant="body">
              Rozpocznij swoją przygodę z naszą platformą treningową online.
              Przekonaj się, że możemy pomóc Ci osiągnąć swoje cele treningowe i
              poprawić jakość swojego treningu, również podczas ćwiczeń online!
            </Typography.P>
          </Text>
          <Text className="textSec">
            <Typography.H5 variant="h5">
              Nowi trenerzy już wkrótce!
            </Typography.H5>
            <Typography.P variant="body">
              Już wkrótce grono naszych trenerów będzie się powiększać, dając Ci
              większy wybór. Będziesz mógł/mogła wybrać trenera, który najlepiej
              odpowiada Twoim potrzebom i preferencjom. Niezależnie od tego, czy
              chcesz poprawić swoją kondycję, zwiększyć siłę mięśniową, schudnąć
              czy osiągnąć inne cele związane z treningiem, znajdziesz trenera,
              który pomoże Ci w drodze do sukcesu.
            </Typography.P>
          </Text>
        </Grid>
      </BottomSection>
    </>
  );
};
