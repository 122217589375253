import { useEffect, useState } from "react";
import { Box, Flex, Section } from "components";
import { SectionHeader } from "components/SectionHeader";
import { H3, P } from "components/Typography/Typography";
import { Button } from "components/_form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import { getPayment } from "api/payments";
import { IOrder } from "types/payments";
import { paymentToOrderConverter } from "utilities/orders";

const ClickableP = styled(P)`
  cursor: pointer;
`;

const Row = styled(Flex)`
  min-height: 80px;
  padding-block: 15px;
  align-items: center;
  flex-flow: row wrap;
  gap: 5px 15px;
  border-bottom: 1px solid
    ${({ theme }) => rgba(theme.palette.text.primary, 0.15)};
`;

const Status = styled(P)<{ isPaid?: boolean }>`
  color: ${({ isPaid, theme }) =>
    isPaid ? theme.palette.success.main : theme.palette.error.main};
`;

export const OrderDetailsView = () => {
  const [orderDetails, setOrderDetails] = useState<IOrder>();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchOrderDetails = async () => {
    try {
      if (!id) return;
      const response = await getPayment(id);
      const payment = response.data;
      const orderData = paymentToOrderConverter(payment);
      setOrderDetails(orderData);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [id]);

  return (
    <>
      <SectionHeader>
        <Flex alignItems="center" gap="15px">
          <ClickableP
            variant="body"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("ordersView.header")}
          </ClickableP>
          <P variant="body">/</P>
          <H3 variant="h3">{id}</H3>
        </Flex>
      </SectionHeader>
      <Section color="light" style={{ paddingBlock: "30px" }}>
        <Flex flexDirection="column" width="100%">
          <Row>
            <P variant="body">{t("ordersView.table.subscription")}:</P>
            <P variant="body" fontWeight={600}>
              {orderDetails?.subscription}
            </P>
          </Row>
          <Row>
            <P variant="body">{t("ordersView.table.totalAmount")}:</P>
            <P variant="body" fontWeight={600}>
              {orderDetails?.totalAmount} {orderDetails?.totalAmount && t("zl")}
            </P>
          </Row>
          <Row>
            <P variant="body">{t("ordersView.table.status")}:</P>
            <Status variant="body" isPaid={orderDetails?.isPaid}>
              {orderDetails?.isPaid
                ? t("ordersView.paid")
                : t("ordersView.unpaid")}
            </Status>
            <Button
              variant="secondary"
              disabled={orderDetails?.isPaid}
              label={t("buttons.pay")}
            />
          </Row>
          <Box mt={4}>
            <Button
              variant="error"
              label={t("buttons.cancelOrder")}
              onClick={() => {}}
            />
          </Box>
        </Flex>
      </Section>
    </>
  );
};
