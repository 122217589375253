import { IProfile, IUser, IUserUpdateReq } from "types/user";
import request from "./index";
import { serialize } from "object-to-formdata";

export const getMe = () =>
  request<{ data: IUser }>({
    options: {
      url: `/users/me`,
      method: "get",
    },
  });

export const updateProfile = (payloadData: IUserUpdateReq) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(payloadData.profile)) {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        formData.append(`profile[${key}][]`, item);
      });
    } else {
      formData.append(`profile[${key}]`, value);
    }
  }

  return request<{ data: IUser }>({
    options: {
      url: "/profile",
      method: "patch",
      data: formData,
    },
    handleSubmit: (response) => {
      if (response.data.data)
        localStorage.setItem("currentUser", JSON.stringify(response.data.data));
      return response.data;
    },
  });
};
