import { FC, useState } from "react";
import { Box, Flex, Loader, Section } from "components";
import { Button, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "validation/authSchemas";
import { IResetPassword } from "types/forms/auth";
import { resetPassword } from "../api/authApi";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Wrapper = styled(Flex)`
  max-width: 480px;
`;

export const ResetPasswordView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<IResetPassword["user"]>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(resetPasswordSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await resetPassword({ user: data });
      if (!response) return;
      navigate("/");
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Section color="light">
      <Loader isLoading={isLoading} />
      <Wrapper
        my={4}
        justifyContent="center"
        flexWrap="wrap"
        flexDirection="column"
      >
        <form onSubmit={onSubmit}>
          <TextField
            type="email"
            labelColor="dark"
            label={t("form.email.label")}
            placeholder={t("form.email.placeholder")}
            {...register("email")}
            error={t(errors.email?.message || "")}
            autoComplete="email"
          />

          <Box my={2}>
            <Button fullWidth type="submit" disabled={!formState.isValid}>
              {t("send_email")}
            </Button>
          </Box>
        </form>
      </Wrapper>
    </Section>
  );
};
