import { getTraining } from "api/trainings";
import { Box, Flex, Typography } from "components";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "styles/theme";
import { ITraining } from "types/trainings";
import { ReactComponent as Calendar } from "assets/icons/ico-calendar.svg";
import { DateBox } from "../CoursesView/components/CourseCard";
import moment from "moment";
import { dateFormat } from "constants/dateFormats";
import { useTranslation } from "react-i18next";
import { IBuyElement } from "./BuyView";

const Image = styled(Box)<{ image?: string | null }>`
  background-color: ${({ theme }) => theme.palette.text.grey};
  border-radius: 5px;
  width: 75%;
  height: 180px;

  @media ${device.tablet} {
    width: 100%;
    height: 150px;
  }

  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
`;

export const CourseDetails = ({ id }: IBuyElement) => {
  const { t } = useTranslation();
  const [displayData, setDisplayData] = useState<ITraining>();

  const loadData = async () => {
    const { data } = await getTraining(Number(id));
    setDisplayData(data);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Image image={displayData?.thumbnail} />
      <Typography.H5 variant="h5" color="secondary">
        {displayData?.title}
      </Typography.H5>
      {!!displayData?.description && (
        <Typography.P variant="body" color="secondary">
          {displayData?.description}
        </Typography.P>
      )}
      <DateBox>
        <Calendar height="16px" />
        <Typography.P variant="body" fontWeight={700} color="secondary">
          {t("coursesView.courseDate")}:
        </Typography.P>
        <Typography.P variant="body" color="secondary">
          {moment(displayData?.startAt).format(dateFormat)} -
          {moment(displayData?.finishAt).format(dateFormat)}
        </Typography.P>
      </DateBox>

      <Flex alignItems="center" gridColumnGap={"10px"}>
        <Typography.H6 variant="h6" color="secondary">
          {t("buyView.price")}
        </Typography.H6>
        <Typography.H5 variant="h5" color="pink">
          {Number(displayData?.price).toFixed(2)} {t("zl")}
        </Typography.H5>
      </Flex>
    </>
  );
};
