import { Section } from "components";
import { SectionHeader } from "components/SectionHeader";
import { H3 } from "components/Typography/Typography";
import { useTranslation } from "react-i18next";
import { OrdersTable } from "./components/OrdersTable";

export const OrdersView = () => {
  const { t } = useTranslation();
  return (
    <>
      <SectionHeader>
        <H3 variant="h3" color="primary">
          {t("ordersView.header")}
        </H3>
      </SectionHeader>
      <Section color="light" style={{ paddingBlock: "30px" }}>
        <OrdersTable />
      </Section>
    </>
  );
};
