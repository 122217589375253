import React, { CSSProperties } from "react";
import { Modal, IModal } from "components/Modal/Modal";
import styled from "styled-components";
import { FILE_URL } from "constants/paths";
import { Box } from "components";
import { ReactComponent as CrossIcon } from "assets/icons/bx-x.svg";

interface IPhotoModal extends Omit<IModal, "styles" | "children"> {
  src: string;
  alt: string;
}

const CustomCloseButton = styled(Box)`
  cursor: pointer;
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;

  svg {
    fill: #fff;
    width: 16px; // adjust the size as needed
    height: 16px; // adjust the size as needed
  }
`;

const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const StyledImage = styled.img`
  max-width: 80vw;
  max-height: 80vh;
  object-fit: contain;
`;

export const PhotoModal: React.FC<IPhotoModal> = ({
  isOpen,
  onCancelClick,
  src,
  alt,
}) => {
  const photoModalStyles: CSSProperties = {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  };

  const photoCardStyles: CSSProperties = {
    borderRadius: "0",
    padding: "0",
    maxWidth: "80vw",
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      styles={photoModalStyles}
      cardStyles={photoCardStyles}
      showCloseButton={false}
    >
      <CustomCloseButton aria-label="Close" onClick={onCancelClick}>
        <CrossIcon />
      </CustomCloseButton>
      <PhotoWrapper>
        <StyledImage src={`${FILE_URL}${src}`} alt={alt} />
      </PhotoWrapper>
    </Modal>
  );
};
