import { Box } from "components";
import styled, { css } from "styled-components";
import { rgba } from "utilities/rgba";

export const Wrapper = styled(Box)`
  overflow-x: scroll;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
`;
const sharedStyle = css`
  padding-block: 15px;
  text-align: left;
`;

export const Header = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.palette.text.primary};
`;

export const Row = styled.tr`
  border-bottom: 1px solid
    ${({ theme }) => rgba(theme.palette.text.primary, 0.15)};
`;

export const Th = styled.th`
  ${sharedStyle};
  text-transform: uppercase;
`;

export const Td = styled.td`
  ${sharedStyle};
  min-width: 100px;
`;

export const Status = styled(Td)<{ isPaid?: boolean }>`
  color: ${({ isPaid, theme }) =>
    isPaid ? theme.palette.success.main : theme.palette.error.main};
`;
