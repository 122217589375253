import { Flex } from "components/Flex";
import { P } from "components/Typography/Typography";
import { ReactComponent as CheckmarkIcon } from "assets/icons/checkmark.svg";
import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import { Link } from "components/Link";
import { ErrorText } from "../ErrorText";

export interface CheckboxI extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  labelLink?: { text?: string; href?: string };
  error?: string;
  name?: string;
  id?: string;
  checked?: boolean;
  color?: CheckboxColor;
  required?: boolean;
}

type CheckboxColor = "light" | "dark";

const Label = styled.label<{ color?: CheckboxColor }>`
  display: flex;
  align-content: center;
  cursor: pointer;
  white-space: pre-wrap;

  + label {
    cursor: pointer;
    ${({ theme, color }) => `
      color: ${
        color === "dark"
          ? theme.palette.text.primary
          : theme.palette.text.secondary
      };
    `};
  }
`;

const Control = styled(Flex)<{ color?: CheckboxColor }>`
  ${({ theme, color }) => `    
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border: 1px solid  ${
      color === "dark"
        ? theme.palette.text.primary
        : theme.palette.accent.yellow.main
    };
    border-radius: 3px;
    top: 0;

    :hover, :focus {
      outline: 2px solid ${rgba(
        color === "dark"
          ? theme.palette.text.primary
          : theme.palette.accent.yellow.main,
        0.5,
      )};
      outline-offset: 2px;
    }
  `}
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)<{ color?: CheckboxColor }>`
  ${({ theme, color }) => `
    display: none;
    position: absolute;
    top: 0px;
    left: 1px;
    width: 16px;
    height: 16px;
    fill: ${
      color === "dark"
        ? theme.palette.text.secondary
        : theme.palette.text.primary
    };
  `}
`;

interface IInput {
  error?: boolean;
  color?: CheckboxColor;
}

const Input = styled.input<IInput>`
  ${({ theme, error, color }) => `
    opacity: 0;
    width: 22px;
    height: 22px;
    display: none;
    border-radius: 3px;
    + ${Control} {
      ${error ? `border-color: ${theme.palette.error.main}` : ""};
    }

    &:checked {
      + ${Control} {
        background-color: ${
          color === "dark"
            ? theme.palette.text.primary
            : theme.palette.accent.yellow.main
        };
        border-color: ${
          error
            ? theme.palette.accent.red
            : color === "dark"
            ? theme.palette.text.primary
            : theme.palette.accent.yellow.main
        };

        ${StyledCheckmarkIcon} {
          display: block;
        }
      }
    }

    &:disabled {
      + ${Control} {
        opacity: 0.4;

        background-color: ${theme.palette.neutral.medium};
        border-color: ${theme.palette.neutral.medium};
      }
    }
  `}
`;

const StyledFlex = styled(Flex)<{ required?: boolean }>`
  white-space: pre-wrap;
  ${({ required, theme }) =>
    required &&
    `::after {
    content: "*";
    margin-left: 4px;
    color: ${theme.palette.error.main};
  }
`};
`;

export const Checkbox: React.FC<CheckboxI> = ({
  label,
  name,
  error,
  checked,
  onChange,
  id,
  color = "light",
  labelLink,
  required,
}) => {
  return (
    <Flex flexDirection="column">
      <Label>
        <Input
          type="checkbox"
          checked={checked}
          name={name}
          onChange={onChange}
          id={id}
          tabIndex={0}
          color={color}
        />
        <Control color={color}>
          <StyledCheckmarkIcon color={color} />
        </Control>
        <StyledFlex required={required}>
          <P
            variant="body2"
            color={color === "light" ? "secondary" : "primary"}
          >
            {label}
          </P>
          {labelLink && (
            <Link
              to={labelLink?.href}
              underline
              checkbox
              target="_blank"
              color={color === "light" ? "secondary" : "primary"}
            >
              {" "}
              {labelLink?.text}
            </Link>
          )}
        </StyledFlex>
      </Label>
      {!!error && <ErrorText show={!!error}>{error}</ErrorText>}
    </Flex>
  );
};
