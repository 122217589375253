import { ICategory } from "types/exercises";
import request from "./index";

export const getCategories = () =>
  request<{ data: ICategory[] }>({
    options: {
      url: `/categories`,
      method: "get",
    },
  });

export const getCategory = (id: number | string) =>
  request<{ data: ICategory }>({
    options: {
      url: `/categories/${id}`,
      method: "get",
    },
  });
