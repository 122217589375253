import MDEditor from "@uiw/react-md-editor";
import { getPage } from "api/pages";
import { Section, SectionHeader, Typography } from "components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IPage } from "types/pages";

export const CustomView = () => {
  const [content, setContent] = useState<IPage>({});
  const { slug } = useParams<{ slug: string }>();

  const getContent = async () => {
    if (slug) {
      const { data } = await getPage(slug);
      setContent(data);
    }
  };

  useEffect(() => {
    getContent();
  }, [slug]);

  return (
    <>
      <SectionHeader>
        <Typography.H3 variant="h3" color="primary">
          {content.name}
        </Typography.H3>
      </SectionHeader>
      <Section color="light">
        <MDEditor.Markdown
          style={{ padding: 10, background: "transparent" }}
          source={content.richContent}
          linkTarget="_blank"
        />
      </Section>
    </>
  );
};
