import { Flex } from "components/Flex";
import React from "react";
import styled from "styled-components";
import { ReactComponent as PrevButton } from "assets/icons/left.svg";
import { ReactComponent as NextButton } from "assets/icons/right.svg";
import { Typography } from "components";
import { rgba } from "utilities/rgba";

interface IPagination {
  actualPage: number;
  totalPages: number;
  setActualPage: React.Dispatch<React.SetStateAction<number>>;
}

const Wrapper = styled(Flex)`
  padding-bottom: 30px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;

const Button = styled.button<{ disable: boolean }>`
  all: unset;
  cursor: pointer;
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
  transition: all 100ms linear;

  ${({ theme, disable }) => `
    :hover, :focus-visible{
      ${
        !disable &&
        `background-color:  ${rgba(theme.palette.text.primary, 0.1)};`
      }
    }
    :active {
      ${
        !disable &&
        `background-color:  ${rgba(theme.palette.text.primary, 0.3)};`
      }
    }

    ${disable && `cursor: auto;svg path{ stroke: ${theme.palette.text.grey};}`}
    
    `}
`;

export const Pagination = ({
  actualPage,
  totalPages,
  setActualPage,
}: IPagination) => {
  const getPrevPage = () => {
    if (actualPage > 1) setActualPage((prev) => prev - 1);
  };

  const getNextPage = () => {
    if (actualPage < totalPages) setActualPage((prev) => prev + 1);
  };

  return (
    <Wrapper>
      <Button onClick={() => getPrevPage()} disable={actualPage <= 1}>
        <PrevButton />
      </Button>
      <Typography.P variant="body" color="primary">
        {actualPage}
      </Typography.P>
      <Typography.P variant="body" color="primary">
        z
      </Typography.P>
      <Typography.P variant="body" color="primary">
        {totalPages}
      </Typography.P>
      <Button onClick={() => getNextPage()} disable={actualPage >= totalPages}>
        <NextButton />
      </Button>
    </Wrapper>
  );
};
