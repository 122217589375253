import React from "react";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "assets/icons/ico-plus.svg";
import { FILE_URL } from "constants/paths";
import { ReactComponent as DeleteIcon } from "assets/icons/bx-x.svg";

const DeleteButton = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.accent.light.mediumDark};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.accent.light.dark};
  }
`;

export const AddPhotoContainer = styled.div`
  width: 200px;
  height: 120px;
  ${({ theme }) => `
    background: ${theme.palette.accent.light.mediumDark};
  `};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
`;

export const AddPhotoTile = ({
  onClick,
}: {
  onClick: (event: any) => void;
}) => (
  <AddPhotoContainer key="add-photo" onClick={onClick}>
    <PlusIcon />
  </AddPhotoContainer>
);

const StyledTile = styled.img`
  width: 200px;
  height: 120px;
  border-radius: 8px;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
`;

export const TileContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
`;

interface PhotoTileProps {
  src: string;
  alt: string;
  onClick: (src: string) => void;
  onDelete?: (src: string) => void;
  isEditing?: boolean;
}
const Wrapper = styled.div`
  width: 200px;
  height: 120px;
  position: relative;
  display: inline-block;
`;

export const Tile: React.FC<PhotoTileProps> = ({
  src,
  alt,
  onClick,
  onDelete,
  isEditing = false,
}) => {
  return (
    <Wrapper>
      <StyledTile
        src={`${FILE_URL}${src}`}
        alt={alt}
        onClick={() => onClick(src)}
      />
      {isEditing && (
        <DeleteButton
          onClick={() => {
            onDelete && onDelete(src);
          }}
        >
          <DeleteIcon />
        </DeleteButton>
      )}
    </Wrapper>
  );
};
