import { Box, Flex, Line, Loader, Section, SectionHeader } from "components";
import { H3, H5, H6, P } from "components/Typography/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import ReactPlayer from "react-player/lazy";
import { device } from "styles/theme";
import { ReactComponent as MuscleZones } from "assets/icons/muscle-zones.svg";
import ExerciseCard from "./components/ExerciseCard";
import { ICategory, IExercise } from "types/exercises";
import {
  getExercise,
  getExerciseVideo,
  getExerciseVideoFileUrl,
} from "api/exercises";
import { toast } from "react-toastify";
import { getCategory } from "api/categories";
import { FILE_URL } from "constants/paths";

const ClickableP = styled(P)`
  cursor: pointer;
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
  gap: 30px;
`;

const StyledMusceZones = styled(MuscleZones)`
  max-width: 550px;
  .active {
    fill: ${({ theme }) => theme.palette.accent.pink.main};
  }
`;

const ExcercisesContainer = styled(Flex)`
  gap: 30px;
  flex-flow: row nowrap;
  overflow-x: auto;
  margin-inline: 0 auto;
  width: fit-content;
  max-width: 100%;
`;

export const ExerciseDetailsView = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState<IExercise>();
  const [video, setVideo] = useState<string>();

  const [exercises, setExercises] = useState<ICategory>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const exerciseRes = await getExercise(Number(id));
      if (!exerciseRes) return;
      const exerciseVideoRes = await getExerciseVideo(
        exerciseRes.data.exerciseVideo,
      );

      if (!exerciseVideoRes) return;
      const exerciseVideoFileUrlRes = await getExerciseVideoFileUrl(
        `${FILE_URL}${exerciseVideoRes.data.video}`,
      );
      setData(exerciseRes.data);
      setVideo(exerciseVideoFileUrlRes.request.responseURL);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExercises = async () => {
    if (data?.categories[0]) {
      setIsLoading(true);
      try {
        const result = await getCategory(Number(data.categories[0].id));
        if (!result) return;
        setExercises(result.data);
      } catch (e: any) {
        toast.error(e.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // if (data?.bodyParts) {
    //   let zones = data.bodyParts.map((e) => "." + e).join(", ");
    //   const elements = document.querySelectorAll(zones);
    //   elements.forEach((element) => element.classList.add("active"));
    // }

    if (data) fetchExercises();
  }, [data]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <SectionHeader>
        <Flex alignItems="center" gap="15px">
          <ClickableP
            variant="body"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t("exercisesAtlasView.exercisesAtlas")}
          </ClickableP>
          <P variant="body">/</P>
          <H3 variant="h3">{data?.title}</H3>
        </Flex>
      </SectionHeader>
      <Section color="light" style={{ paddingBlock: "30px" }}>
        <Grid mb={4}>
          <Flex width="100%" flexDirection="column" gap="15px">
            <H6 variant="h6" color="grey">
              {data?.categories[0]?.name}
            </H6>
            <H3 variant="h3" fontWeight={400}>
              &quot;{data?.title}&quot;
            </H3>
            <ReactPlayer
              url={video}
              controls
              light={`${FILE_URL}${data?.thumbnail ?? ""}`}
              width="100%"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
            <P variant="body">{data?.description}</P>
          </Flex>
          <Flex alignItems="center" justifyContent="center">
            <StyledMusceZones />
          </Flex>
        </Grid>
        <H5 variant="h5" fontWeight={400}>
          {t("exercisesAtlasView.seeMoreInCategory")}{" "}
          <b>{data?.categories[0]?.name}</b>
        </H5>
        <Line />
        <ExcercisesContainer>
          {exercises?.exercises.map((e) => {
            return (
              <ExerciseCard
                key={e.id}
                exercise={{
                  id: e.id,
                  title: e.title,
                  isFree: e.free,
                  thumbnail: e.thumbnail,
                }}
              />
            );
          })}
        </ExcercisesContainer>
      </Section>
    </>
  );
};
