import { Flex, Section } from "components";
import { P } from "components/Typography/Typography";
import EUflags from "assets/images/eu-logos-white.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { EUImage } from "views/EUInfoView";

const Wrapper = styled(Section)`
  padding-block: 30px;
  cursor: pointer;
`;

const EUsection = () => {
  const navigate = useNavigate();

  return (
    <Wrapper color="transparent" onClick={() => navigate("/info/eu-info")}>
      <Flex
        width="100%"
        justifyContent="space-between"
        gap="20px"
        flexDirection="column"
        alignItems="center"
      >
        {/*<P variant="body" textAlign="center" color="secondary">*/}
        {/*  Tytuł projektu: Wdrożenie nowego modelu funkcjonowania firmy ZDYBKA*/}
        {/*  KATARZYNA ZDYB w warunkach pandemii, poprzez stworzenie innowacyjnej*/}
        {/*  treningowej aplikacji webowej*/}
        {/*</P>*/}
        <P variant="body" textAlign="center" color="secondary">
          Sfinansowano w ramach reakcji Unii na pandemię COVID 19, realizujemy
          projekt ze wsparciem z REACT-EU W RAMACH DZIAŁANIA 6.2 WSPARCIE MŚP W
          OBSZARZE CYFRYZACJI - BONY NA CYFRYZACJĘ W RAMACH PROGRAMU
          OPERACYJNEGO INTELIGENTNY ROZWÓJ 2014-2020
        </P>

        <EUImage src={EUflags} alt="EU project logo" />
      </Flex>
    </Wrapper>
  );
};

export default EUsection;
