import { Box } from "components/Box";
import React, { useEffect } from "react";
import { default as reactSelect } from "react-select";
import styled from "styled-components";
import { FieldLabel as Label } from "components/_form/FieldLabel";
import { ErrorText } from "../ErrorText";

export interface IOption {
  label: string;
  value: string;
}

interface ISelect {
  error?: string;
  isClearable?: boolean;
  options: IOption[];
  selectedOptions?: IOption[] | IOption;

  onChange?(e: IOption | IOption[]): void;

  transparent?: boolean;
  label?: string;
  hideElements?: boolean;
  isMulti?: boolean;
  placeholder?: string;
}

interface IStyledTags {
  transparent?: ISelect["transparent"];
}

const StyledSelect = styled(reactSelect)<IStyledTags>`
  ${({ transparent }) => `
    ${transparent && `& > div:nth-child(2) {background-color: transparent;}`}
  `}
  .valueContainer {
    opacity: 1;
  }
`;

export const Select: React.FC<ISelect> = ({
  error,
  selectedOptions,
  isClearable = false,
  options,
  onChange,
  label,
  transparent,
  hideElements,
  isMulti,
  placeholder,
}) => {
  const [value, setValue] = React.useState<IOption[] | IOption>(
    selectedOptions || [],
  );

  const customStyles: { [key: string]: any } = {};

  if (hideElements) {
    customStyles.multiValue = (provided: any) => ({
      ...provided,
      display: "none",
    });
    customStyles.indicatorsContainer = (provided: any) => ({
      ...provided,
      display: "none",
    });
  }

  const handleChange = (newValue: IOption) => {
    const val = isMulti
      ? Array.isArray(newValue)
        ? newValue
        : [newValue]
      : newValue;
    setValue(val);
    if (onChange) onChange(val);
  };

  useEffect(() => {
    if (selectedOptions) setValue(selectedOptions);
  }, [selectedOptions]);

  return (
    <>
      <Box mb={3}>
        {label && <Label error={error}>{label}</Label>}
        <StyledSelect
          /*// @ts-ignore*/
          onChange={handleChange}
          isClearable={isClearable}
          styles={customStyles}
          transparent={transparent}
          value={value}
          isMulti={isMulti}
          closeMenuOnSelect={true}
          options={options}
          placeholder={placeholder}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </Box>
    </>
  );
};
