import { FieldLabel as Label } from "components/_form/FieldLabel";
import React, { TextareaHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import { ErrorText } from "../ErrorText";
import { IFieldLabel } from "../FieldLabel/FieldLabel";

export interface ITextareaField
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  unit?: string;
  icon?: React.ReactNode;
  bgColor?: string;
  labelColor?: IFieldLabel["color"];
}

interface StyledTextareaI {
  error?: ITextareaField["error"];
  unit?: ITextareaField["unit"];
  icon?: ITextareaField["icon"];
  bgColor?: ITextareaField["bgColor"];
}

const unitWidthInPx = 44;
const iconSizeInPx = 24;
const iconPositionRight = 4;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Unit = styled.div`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    right: 0;   
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${unitWidthInPx}px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      height: 24px;
      width: 1px;
      background-color: ${theme.palette.neutral.medium};
    }
  `};
`;

const Icon = styled.div`
  position: absolute;
  right: ${iconPositionRight}px;
  top: 8px;
  display: flex;
  height: ${iconSizeInPx}px;
  width: ${iconSizeInPx}px;
`;

const StyledTextarea = styled.textarea<StyledTextareaI>`
  ${({ theme, unit, icon, bgColor }) => `
    display: block;
    width: 100%;
    height: 100%;
    padding: 8px 16px;
    resize: none;
    font-family: Poppins;
    ${unit ? `padding-right: ${16 + unitWidthInPx}px` : ""};
    ${icon ? `padding-right: ${16 + iconSizeInPx + iconPositionRight}px` : ""};
    font-size: 16px;
    color: ${theme.palette.text.primary};
    background-color: ${bgColor ? bgColor : theme.palette.neutral.veryLight};

    border: 2px solid ${theme.palette.accent.light.medium};
    border-radius: 30px;
    outline: none;

    :focus-visible {
      border-color: ${theme.palette.accent.pink.main};

      + ${Unit}::before {
        background-color: ${theme.palette.accent.pink.main};
      }

      + ${Icon} {
        color: ${theme.palette.accent.pink.main};
      }
    }

    :disabled {
      color ${rgba(theme.palette.text.primary, 0.4)};
      background-color: ${theme.palette.neutral.veryLight};

      + ${Unit} {
        color: ${rgba(theme.palette.text.primary, 0.4)};

        &::before {
          background-color: ${rgba(theme.palette.text.primary, 0.4)};
        }
      }

      + ${Icon} {
        color: ${rgba(theme.palette.text.primary, 0.4)};
      }
    }
  `};
`;

export const TextareaField = React.forwardRef<
  HTMLTextAreaElement,
  ITextareaField
>(
  (
    { label, error, unit, icon, disabled, bgColor, labelColor, ...props },
    ref,
  ) => (
    <InputWrapper>
      {label ? (
        <Label error={error} disabled={disabled} color={labelColor}>
          {label}
        </Label>
      ) : null}
      <>
        <StyledTextarea
          ref={ref}
          error={error}
          unit={unit}
          icon={icon}
          bgColor={bgColor}
          disabled={disabled}
          {...props}
        />
        {unit && <Unit>{unit}</Unit>}
        {icon && <Icon>{icon}</Icon>}
      </>
      <ErrorText show={!!error}>{error}</ErrorText>
    </InputWrapper>
  ),
);

TextareaField.displayName = "TextareaField";
