import { FC, useState } from "react";
import { Box, Flex, Loader, Section, Typography } from "components";
import { Button, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "validation/authSchemas";
import { ILogin } from "types/forms/auth";
import { login } from "../api/authApi";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { device } from "styles/theme";
import LoginImage from "assets/images/loginBanner.png";

export const Wrapper = styled(Section)`
  padding-block: 80px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(2, 1fr);

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
  }
`;

export const InputWrapper = styled(Flex)`
  flex-flow: column wrap;
  height: 100%;
  gap: 25px;
  width: 100%;
  max-width: 480px;
  @media ${device.tablet} {
    max-width: 100%;
  }
  justify-self: start;
  align-self: start;
  height: min-content;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
`;

export const LoginView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm<ILogin>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const response = await login(data);
      if (!response) return;
      navigate("/");
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <Wrapper color="dark" dots>
      <Loader isLoading={isLoading} />
      <InputWrapper
        justifyContent="center"
        flexWrap="wrap"
        flexDirection="column"
        height="100%"
      >
        <Typography.H3 variant="h3" color="yellow">
          {t("loginView.header")}
        </Typography.H3>
        <form onSubmit={onSubmit}>
          <TextField
            type="email"
            label={t("form.email.label")}
            placeholder={t("form.email.placeholder")}
            {...register("email")}
            error={t(errors.email?.message || "")}
            autoComplete="email"
          />
          <TextField
            type="password"
            label={t("form.password.label")}
            placeholder={t("form.password.placeholder")}
            {...register("password")}
            error={t(errors.password?.message || "")}
            autoComplete="password"
          />

          <Box my={2}>
            <Button
              type="submit"
              disabled={!formState.isValid}
              variant="secondary"
            >
              {t("buttons.signIn")}
            </Button>
          </Box>
        </form>
        <Flex
          flexDirection="row"
          flexWrap="nowrap"
          alignItems="center"
          gridColumnGap="10px"
        >
          <Typography.P variant="body" color="secondary">
            {t("notAccountAlready")}
          </Typography.P>
          <Button
            variant="secondary"
            textOnly
            onClick={() => navigate("/register")}
            label={t("buttons.singUp")}
          />
        </Flex>
        <Button
          variant="primary"
          textOnly
          onClick={() => navigate("/password-reset")}
          label={t("buttons.forgotPassword")}
        />
      </InputWrapper>
      <Image src={LoginImage} alt="" />
    </Wrapper>
  );
};
