import { FC, useState, useEffect } from "react";
import { Box } from "components";
import { Button } from "components/_form";
import { resetPassword } from "api/authApi";
import { useTranslation } from "react-i18next";
import { Flex } from "components";
import { IModal, Modal } from "components/Modal/Modal";
import { H6 } from "components/Typography/Typography";
import styled from "styled-components";

interface IChangePasswordModal extends Omit<IModal, "styles" | "children"> {
  userEmail: string;
}

const Wrapper = styled(Flex)`
  flex-flow: column nowrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 230px;
`;

export const ChangePasswordModal = ({
  isOpen,
  onCancelClick,
  userEmail,
}: IChangePasswordModal) => {
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setIsSuccess(false);
    }
  }, [isOpen]);

  const handleSendResetEmail = async () => {
    try {
      const response = await resetPassword({ user: { email: userEmail } });
      if (!response) return;
      setIsSuccess(true);
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancelClick={onCancelClick}
      showCloseButton={!isSuccess}
    >
      <Wrapper>
        {isSuccess ? (
          <>
            <H6 mt={3} variant="h6">
              {t("success_message")}
            </H6>
            <Box mt="auto" mb={3}>
              <Button fullWidth onClick={onCancelClick}>
                {t("close")}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <H6 variant="h6" mt={3}>
              {t("confirm_send_reset_email") + userEmail + " ?"}
            </H6>
            <Box mt="auto" mb={3}>
              <Button fullWidth onClick={handleSendResetEmail}>
                {t("send")}
              </Button>
            </Box>
          </>
        )}
      </Wrapper>
    </Modal>
  );
};
