import React, { useState, useEffect } from "react";
import { Box, Flex, Section, Typography } from "components";
import { ReactComponent as SuccessIcon } from "assets/icons/payment-success.svg";
import { ReactComponent as FailedIcon } from "assets/icons/payment-failed.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "components/_form";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IPayment } from "types/payments";
import { getPayment } from "api/payments";

const StyledSection = styled(Section)`
  padding-block: 150px;
  display: flex;
  flex-flow: column nowrap;
  gap: 45px;
`;

export const TransactionReturnView = () => {
  const [payment, setPayment] = useState<IPayment>();
  const [loading, setLoading] = useState<boolean>(true);
  const orderId = localStorage.getItem("orderId");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchPayment = async (id: number | string) => {
    try {
      const response = await getPayment(id);
      setPayment(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      fetchPayment(orderId);
    }
  }, []);

  if (!payment) return <></>;

  const handleClick = () => {
    navigate("/");
  };

  return (
    <StyledSection color="dark" dots>
      {payment.status === "completed" ? <SuccessIcon /> : <FailedIcon />}
      <Flex flexDirection="column">
        <Typography.H6 variant="h6" fontWeight={400} color="secondary">
          {t(
            `transactionReturnView.${
              payment.status === "completed" ? "thankYou" : "somethingWrong"
            }`,
          )}
        </Typography.H6>
        <Typography.H5 variant="h5" color="secondary">
          {t(
            `transactionReturnView.${
              payment.status === "completed" ? "successHeader" : "failedHeader"
            }`,
          )}
        </Typography.H5>
        {payment.status === "completed" && (
          <Typography.P variant="body" color="secondary">
            {t(`transactionReturnView.moreInformationOnMail`)}
          </Typography.P>
        )}
      </Flex>
      <Box>
        <Button variant="secondary" onClick={handleClick}>
          {t(
            `buttons.${
              payment.status === "completed" ? "backToHomeView" : "tryAgain"
            }`,
          )}
        </Button>
      </Box>
    </StyledSection>
  );
};
