import { Box } from "components/Box";
import { Flex } from "components/Flex";
// import { Tooltip } from "components/Tooltip";
import { theme } from "styles/theme";
import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import { rgba } from "utilities/rgba";

export interface ButtonI extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "dark" | "light" | "error";
  disabled?: boolean;
  label?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  bordered?: boolean;
  tooltipContent?: string;
  textOnly?: boolean;
}

interface StyledButtonI {
  variant: NonNullable<ButtonI["variant"]>;
  icon?: ButtonI["icon"];
  fullWidth?: ButtonI["fullWidth"];
  bordered?: ButtonI["bordered"];
  textOnly?: ButtonI["textOnly"];
}

const { palette, textStyles } = theme;

const colorVariantsMap = {
  primary: palette.text.primary,
  secondary: palette.text.secondary,
  light: palette.text.primary,
  dark: palette.text.secondary,
  error: palette.text.secondary,
};

const colorHoverVariantsMap = {
  primary: palette.text.primary,
  secondary: palette.text.secondary,
  light: palette.text.primary,
  dark: palette.text.secondary,
  error: palette.text.secondary,
};

const colorDisabledVariantsMap = {
  primary: palette.text.primary,
  secondary: palette.text.secondary,
  light: palette.text.primary,
  dark: palette.text.secondary,
  error: palette.text.secondary,
};

const backgroundColorVariantsMap = {
  primary: palette.accent.yellow.main,
  secondary: palette.accent.pink.main,
  light: palette.accent.light.main,
  dark: palette.accent.dark.main,
  error: palette.error.main,
};

const backgroundColorHoverVariantsMap = {
  primary: palette.accent.yellow.light,
  secondary: palette.accent.pink.light,
  light: palette.accent.light.light,
  dark: palette.accent.dark.light,
  error: palette.error.light,
};

const backgroundColorActiveVariantsMap = {
  primary: palette.accent.yellow.dark,
  secondary: palette.accent.pink.dark,
  light: palette.accent.light.dark,
  dark: palette.accent.dark.dark,
  error: palette.error.dark,
};

const backgroundColorDisabledVariantsMap = {
  primary: rgba(palette.accent.yellow.main, 0.5),
  secondary: rgba(palette.accent.pink.main, 0.5),
  light: rgba(palette.accent.light.main, 0.5),
  dark: rgba(palette.accent.dark.main, 0.5),
  error: rgba(palette.error.main, 0.5),
};

const borderColorVariantsMap = {
  primary: palette.accent.yellow.main,
  secondary: palette.accent.pink.main,
  light: palette.accent.light.main,
  dark: palette.accent.dark.main,
  error: palette.error.main,
};

const StyledButton = styled.button<StyledButtonI>`
  ${({ variant, fullWidth, textOnly }) => `
    display: ${fullWidth ? "flex" : "inline-flex"};
    align-items: center;
    justify-content: center;    
    ${fullWidth ? `width: 100%;` : ""};
    min-height: min-content;
    border-radius: 30px; 
    font-family: Poppins;
    font-weight: ${textStyles.button.fontWeight};
    font-size: ${textStyles.button.fontSize}px;
    line-height: 20px;
    color: ${
      textOnly ? backgroundColorVariantsMap[variant] : colorVariantsMap[variant]
    };
    min-width: ${textOnly ? "min-content" : "200px"};
    background-color: ${
      textOnly ? "transparent" : backgroundColorVariantsMap[variant]
    };

    outline: none;
    cursor: pointer;
    ${!textOnly ? "padding: 15px 25px;" : ""}

    :hover:not(:disabled), :focus:not(:disabled) {
      color: ${
        textOnly
          ? backgroundColorHoverVariantsMap[variant]
          : colorHoverVariantsMap[variant]
      };
      background-color: ${
        textOnly ? "transparent" : backgroundColorHoverVariantsMap[variant]
      };
      ${
        textOnly ? "text-shadow: 0px 0px 15px" : "box-shadow: 0px 0px 15px 0px"
      } ${borderColorVariantsMap[variant]}; 
    }

    :active:not(:disabled) {
      color: ${
        textOnly
          ? backgroundColorActiveVariantsMap[variant]
          : colorHoverVariantsMap[variant]
      };
      background-color: ${
        textOnly ? "transparent" : backgroundColorActiveVariantsMap[variant]
      };
    }

    :disabled {
      pointer-events: none;
      color: ${
        textOnly
          ? backgroundColorDisabledVariantsMap[variant]
          : colorDisabledVariantsMap[variant]
      };
      background-color: ${
        textOnly ? "transparent" : backgroundColorDisabledVariantsMap[variant]
      };
    }
  `}

  transition: all linear 100ms;
`;

export const Button: React.FC<ButtonI> = ({
  label,
  icon,
  bordered,
  variant = "primary",
  type = "button",
  disabled = false,
  fullWidth,
  onClick,
  children,
  textOnly,
}) => (
  // <Tooltip fullWidth={fullWidth} content={tooltipContent}>
  <StyledButton
    type={type}
    icon={icon}
    variant={variant}
    disabled={disabled}
    fullWidth={fullWidth}
    bordered={bordered}
    onClick={onClick}
    textOnly={textOnly}
  >
    <Flex justifyContent="center" alignItems="center">
      {label}
      {icon}
      {children && <Box mx={1}>{children}</Box>}
    </Flex>
  </StyledButton>
  // </Tooltip>
);
