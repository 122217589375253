import { Box, Card, Flex } from "components";
import { H5, P } from "components/Typography/Typography";
import styled from "styled-components";
import { ReactComponent as PointIcon } from "assets/icons/pink-point.svg";
import { useTranslation } from "react-i18next";

const FlexCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const points = [
  "exercisesAtlasView.points.point1",
  "exercisesAtlasView.points.point2",
  "exercisesAtlasView.points.point3",
  "exercisesAtlasView.points.point4",
  "exercisesAtlasView.points.point5",
];

const InfoCard = () => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap="10px">
      <H5 variant="h5">{t("exercisesAtlasView.whatAreWeTraining")}</H5>
      <P variant="body">{t("exercisesAtlasView.clickOnTheMusclePart")}</P>
      <FlexCard>
        <P variant="body" pb="10px">
          {t("exercisesAtlasView.whatWillYouFindinVideos")}
        </P>
        {points.map((e, k) => (
          <Flex key={k} alignItems="center" gap="10px">
            <Box flexShrink={0}>
              <PointIcon />
            </Box>

            <P variant="body" fontWeight={700}>
              {t(e)}
            </P>
          </Flex>
        ))}
      </FlexCard>
    </Flex>
  );
};

export default InfoCard;
