import { Box, Flex, Section } from "components";
import styled from "styled-components";
import { device } from "styles/theme";

export const BackgroundImage = styled(Box)<{ imageUrl: string }>`
  width: 100%;
  height: 250px;
  @media ${device.tablet} {
    height: 180px;
  }
  background-image: url(${({ imageUrl }) => `${imageUrl}`});
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  background-color: ${({ theme }) => theme.palette.background.navbar};
`;

export const UpperSection = styled(Section)`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const HeaderContainer = styled.div<{ color: "pink" | "yellow" }>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${({ theme, color }) =>
    `color: ${
      color === "pink"
        ? theme.palette.accent.pink.main
        : theme.palette.accent.yellow.main
    }`};
`;

export const Header = styled.span`
  white-space: nowrap;
  font-weight: bold;
  font-size: 100px;
  @media ${device.tablet} {
    font-size: 60px;
  }
`;

export const HeaderShadow = styled(Header)`
  font-size: 150px;
  @media ${device.tablet} {
    font-size: 100px;
  }
  opacity: 0.15;
  margin-left: 50px;
  position: absolute;
  left: 0;
`;

export const BottomSection = styled(Section)`
  padding-bottom: 60px;
`;

export const Image = styled(Box)<{ src: string }>`
  width: 100%;
  max-width: 650px;
  height: 550px;
  @media ${device.laptop} {
    max-width: 350px;
    height: 350px;
  }
  background-color: #ffffff;
  border-radius: 5px;
  background-image: url(${({ src }) => `${src}`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
`;

export const Text = styled(Flex)`
  flex-flow: column;
  width: 100%;
  gap: 30px;
`;
