import {
  ISubscriber,
  ISubscriberResponse,
  ISubscribersResponse,
  ISubscriberPostRequest,
} from "types/subscribers";
import request from "./index";
import { stringify } from "qs";

export const getSubscribers = (page: number, perPage: number) =>
  request<ISubscribersResponse>({
    options: {
      url: `/subscribers/?${stringify({ page, per_page: perPage })}`,
      method: "get",
    },
  });

export const getSubscriber = (id: number | string) =>
  request<ISubscriberResponse>({
    options: {
      url: `/subscribers/${id}`,
      method: "get",
    },
  });

export const createSubscriber = (subscriber: ISubscriberPostRequest) =>
  request<ISubscriberResponse>({
    options: {
      url: `/subscribers`,
      method: "post",
      data: {
        subscriber: subscriber,
      },
    },
  });

export const deleteSubscriber = (id: number | string) =>
  request<void>({
    options: {
      url: `/subscribers/${id}`,
      method: "delete",
    },
  });
