import { GoogleMap, Marker } from "@react-google-maps/api";
import React from "react";

interface IMap {
  lat: number;
  lng: number;
}

const MapStyle: React.CSSProperties = {
  height: "350px",
  borderRadius: "5px",
};

export const Map: React.FC<IMap> = ({ lat, lng }) => {
  return (
    <>
      <GoogleMap mapContainerStyle={MapStyle} center={{ lat, lng }} zoom={14}>
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    </>
  );
};
