import { Card, Box } from "components";
import { Button } from "components/_form";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as TmpLogo } from "assets/icons/tmp-icon.svg";
import { ButtonI } from "components/_form/Button/Button";
import { theme } from "styles/theme";
import { rgba } from "utilities/rgba";
import { H6 } from "components/Typography/Typography";
import { useTranslation } from "react-i18next";

interface StyledElementI {
  bgColor: NonNullable<"pink" | "yellow" | "white">;
}

export interface ElementI extends StyledElementI {
  icon?: ReactNode;
  title: string;
  btnText?: string;
  link?: string;
  btnVariant: ButtonI["variant"];
}

const { palette } = theme;

const backgroundColorVariantMap = {
  pink: palette.accent.pink.main,
  yellow: palette.accent.yellow.main,
  white: palette.text.secondary,
};
const textColorVariantMap = {
  pink: palette.text.secondary,
  yellow: palette.text.primary,
  white: palette.text.primary,
};

const StyledWrapper = styled(Box)`
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  gap: 45px;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
`;

const StyledH6 = styled(H6)`
  text-transform: capitalize;
`;

const StyledElement = styled(Card)<StyledElementI>`
  text-align: center;
  min-width: min-content;
  max-width: 440px;
  height: 380px;
  min-height: 150px;
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 36px;
  justify-content: center;
  margin: 0 auto;

  ${({ bgColor }) => `
  background-color: ${backgroundColorVariantMap[bgColor]};
  color: ${textColorVariantMap[bgColor]};
  box-shadow: 0px 7px 50px ${rgba(backgroundColorVariantMap[bgColor], 0.35)};
  `};
`;

export const CardsSection: FC<{ elements: ElementI[] }> = ({ elements }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <StyledWrapper>
      {elements.map((e, k) => {
        const isExternalLink = /^https?:\/\//.test(e.link || "");
        const handleClick = (event: React.MouseEvent) => {
          if (!isExternalLink && e.link) {
            event.preventDefault();
            navigate(e.link);
          }
        };

        return (
          <StyledElement key={k} bgColor={e.bgColor}>
            {e.icon ? (
              e.icon
            ) : (
              <TmpLogo stroke={textColorVariantMap[e.bgColor]} />
            )}
            <StyledH6 variant={"h6"}>{e.title}</StyledH6>
            <a href={e.link || "#"} onClick={handleClick}>
              <Button variant={e.btnVariant} type="button">
                {e.btnText ? e.btnText : t("buttons.findMore")}
              </Button>
            </a>
          </StyledElement>
        );
      })}
    </StyledWrapper>
  );
};
