import { FC, useEffect } from "react";
import { Flex } from "components";

import { confirmEmail } from "../api/authApi";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useQuery } from "hooks/useQuery";

const Wrapper = styled(Flex)`
  max-width: 400px;
  margin: 0 auto;
`;

export const ConfirmEmailView: FC = () => {
  let query = useQuery();

  const handleConfirmEmail = async () => {
    const token = query.get("token");
    if (token) {
      try {
        await confirmEmail(token);
      } catch (e) {
        toast.error("Potwierdzenie nie powiodło się");
      }
    } else {
      toast.error("brak tokenu autoryzacyjnego");
    }
  };

  useEffect(() => {
    handleConfirmEmail();
  }, []);

  return (
    <Wrapper
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="column"
      height="100%"
    >
      Potwierdzenie adresu email
    </Wrapper>
  );
};
