import React from "react";
import { Flex } from "components";
import styled from "styled-components";
import Lottie from "lottie-react";
import loader from "assets/loader/loader.json";
import { rgba } from "utilities/rgba";
import ReactDOM from "react-dom";

const loaderRoot = document.getElementById("modal-root")!;

interface ILoader {
  isLoading: boolean;
}

const StyledFlex = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  ${({ theme }) => `
  background-color: ${rgba(theme.palette.accent.dark.main, 0.8)}
  `}
`;

export const Loader = ({ isLoading }: ILoader) => {
  return ReactDOM.createPortal(
    isLoading ? (
      <StyledFlex
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Lottie loop animationData={loader} />
      </StyledFlex>
    ) : null,
    loaderRoot,
  );
};
