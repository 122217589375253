import { Box } from "components";
import { FC, useEffect, useState } from "react";
import { ReactComponent as Indicator } from "assets/icons/indicator.svg";
import { ReactComponent as IndicatorFill } from "assets/icons/indicator-fill.svg";
import styled from "styled-components";
import { H1 } from "components/Typography/Typography";
import { device } from "styles/theme";

export interface BannerItemI {
  markedText?: string;
  normalText?: string;
  image: string;
}

const BannerContainer = styled(Box)`
  height: 420px;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  position: relative;

  @media ${device.desktop} {
    height: 360px;
  }
  @media ${device.laptop} {
    height: 300px;
  }
  @media ${device.tablet} {
    height: 240px;
  }
  @media ${device.mobile} {
    height: 180px;
  }
`;

const Indicators = styled(Box)`
  position: absolute;
  display: flex;
  flex-flow: row wrap;
  gap: 6px;
  height: fit-content;
  z-index: 1;
  left: 15px;
  bottom: 15px;
`;

const IndicatorButton = styled.button`
  all: unset;
  cursor: pointer;
  :focus-visible {
    svg {
      border-radius: 30px;
      outline: 2px solid ${({ theme }) => theme.palette.text.secondary};
      outline-offset: 2px;
    }
  }
`;

const BannerInnerContainer = styled(Box)`
  white-space: nowrap;
  transition: transform 0.3s;
  height: 100%;
`;

const BannerItem = styled(Box)<{ image: string }>`
  display: inline-block;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  ${({ image }) => `background-image: url(${image});`};
  position: relative;
`;

const BannerText = styled(Box)`
  position: absolute;
  width: 50%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  padding-left: 90px;
  justify-content: center;
  white-space: pre-line;
`;

export const Banner: FC<{ elements: BannerItemI[] }> = ({ elements }) => {
  const [index, setIndex] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(false);

  const updateIndex = (newIndex: number) => {
    if (newIndex < 0) newIndex = elements.length - 1;
    else if (newIndex >= elements.length) newIndex = 0;

    setIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) updateIndex(index + 1);
    }, 3000);

    return () => {
      if (interval) clearInterval(interval);
    };
  });

  return (
    <BannerContainer
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
      onFocus={() => setIsPaused(true)}
      onBlur={() => setIsPaused(false)}
      aria-label="Photo carousel"
    >
      <Indicators>
        {elements.map((e, k) => {
          return (
            <IndicatorButton
              aria-label="Photo indicator"
              type="button"
              key={k}
              onClick={() => setIndex(k)}
            >
              {k === index ? <IndicatorFill /> : <Indicator />}
            </IndicatorButton>
          );
        })}
      </Indicators>
      <BannerInnerContainer
        style={{ transform: `translateX(-${index * 100}%)` }}
      >
        {elements.map((e, k) => {
          return (
            <BannerItem key={k} image={e.image}>
              <BannerText>
                <H1 variant={["h5", "h4", "h3", "h2", "h1"]} color="yellow">
                  {e.markedText}
                </H1>
                <H1 variant={["h5", "h4", "h3", "h2", "h1"]} color="secondary">
                  {e.normalText}
                </H1>
              </BannerText>
            </BannerItem>
          );
        })}
      </BannerInnerContainer>
    </BannerContainer>
  );
};
