interface IUseAccount {
  isLogged: boolean;
  userData: TUserData;
  userRole: TRole;
}

type TUserData = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
};

type TRole = "admin" | "user" | "none";

export const useAccount = (): IUseAccount => {
  const currentUser = localStorage.getItem("currentUser");
  const isLogged = currentUser ? true : false;

  const getUserData = (): TUserData => {
    if (isLogged) {
      let data = JSON.parse(currentUser!);
      return {
        id: data.id ?? 0,
        email: data.email ?? "",
        firstName: data.profile.firstName ?? "",
        lastName: data.profile.lastName ?? "",
      };
    } else {
      return {
        id: 0,
        email: "",
        firstName: "",
        lastName: "",
      };
    }
  };

  const getUserRole = (): TRole => {
    if (isLogged) {
      let data = JSON.parse(currentUser!);
      return data.profile.role;
    } else {
      return "none";
    }
  };

  const userData = getUserData();
  const userRole = getUserRole();

  return { isLogged, userData, userRole };
};
