import { IOrder, IPayment } from "types/payments";

export function formatDate(date: Date): string {
  const formattedDate = date.toLocaleDateString("en-GB"); // "dd/mm/yyyy"
  const [day, month, year] = formattedDate.split("/");
  return `${month}.${day}.${year}`;
}

export const paymentToOrderConverter = (payment: IPayment): IOrder => {
  return {
    id: payment.id,
    orderId: payment.orderId,
    productName: payment.description,
    isPaid: payment.trainingParticipant.paid,
    date: new Date(),
    price: payment.unitPrice.toString(),
    subscription: "brak",
    totalAmount: (payment.unitPrice * payment.quantity).toString(),
  };
};
