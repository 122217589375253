import { P, TypographyType } from "components/Typography/Typography";
import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import styled, { css } from "styled-components";

export interface ILink {
  to?: LinkProps["to"];
  href?: string;
  target?: string;
  checkbox?: boolean;
  children?: React.ReactNode;
  variant?: TypographyType["variant"];
  color?: TypographyType["color"];
  underline?: boolean;
}

const sharedStyle = css`
  all: unset;
  cursor: pointer;
  ${({ theme }) => `

    :hover, :focus-visible{
      color: ${theme.palette.accent.pink.light};
    }
    :active {
      color: ${theme.palette.accent.pink.dark};
    }

  `};
  transition: all linear 100ms;
`;

const StyledRouterLink = styled(RouterLink)<{ underline?: boolean }>`
  ${sharedStyle}
  ${({ underline }) =>
    underline &&
    `text-decoration:underline;
  :hover {
    text-decoration: underline;
  }
  :active {
    text-decoration: underline;
  }`}
`;
const StyledNormalLink = styled.a<{ underline?: boolean }>`
  ${sharedStyle};
  ${({ underline }) =>
    underline &&
    `text-decoration:underline;
  :hover {
    text-decoration: underline;
  }
  :active {
    text-decoration: underline;
  }`}
`;

const Link: React.FC<ILink> = ({
  children,
  to,
  target,
  href,
  variant,
  color,
  checkbox,
  underline,
}) => {
  const commonProps = {
    ...(underline && { underline: true }),
  };

  return href ? (
    <P variant={variant ? variant : checkbox ? "body2" : "body"} color={color}>
      <StyledNormalLink target={target} href={href} {...commonProps}>
        {children}
      </StyledNormalLink>
    </P>
  ) : (
    <P variant={variant ? variant : checkbox ? "body2" : "body"} color={color}>
      <StyledRouterLink target={target} to={to ? to : "/"} {...commonProps}>
        {children}
      </StyledRouterLink>
    </P>
  );
};

export { Link };
