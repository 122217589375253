import { Box, Flex, Typography } from "components";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Calendar } from "assets/icons/ico-calendar.svg";
import { useTranslation } from "react-i18next";
import { Button } from "components/_form";
import { AccessModal } from "components/AccessModal";
import { useNavigate } from "react-router-dom";
import { useAccount } from "hooks/useAccount";

interface ICourseCard {
  courseData: ICourseData;
}

export interface ICourseData {
  title: string;
  description: string;
  price: string;
  isArchival: boolean;
  isFree: boolean;
  id: number;
  image: string | null;
  date?: string;
}

const Element = styled(Box)`
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min-content, 480px));
  gap: 10px 60px;
  align-items: center;
  justify-content: center;
`;

export const DateBox = styled(Flex)`
  width: fit-content;
  height: min-content;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 0px 10px;
  padding: 5px 15px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.palette.text.primary};
`;

const ImageBox = styled(Flex)<{ imageUrl: string }>`
  padding: 10px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  ${({ imageUrl }) => `background-image: url("${imageUrl}")`};

  background-color: ${({ theme }) => theme.palette.text.grey};
  background-size: cover;
  border-radius: 5px;
  width: 100%;
  height: 250px;
`;

const CourseCard = ({ courseData }: ICourseCard) => {
  const { t } = useTranslation();
  const [openModal, setOpenModa] = useState<boolean>(false);
  const { isLogged } = useAccount();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (!isLogged) setOpenModa(true);
    else {
      if (!courseData.isFree) {
        navigate(`/courses/buy/${courseData.id}`);
      } else {
        navigate(`/courses/${courseData.id}`);
      }
    }
  };

  return (
    <>
      <AccessModal
        isOpen={openModal}
        onCancelClick={() => setOpenModa(false)}
        title={
          courseData.isFree
            ? t("coursesView.accessModalFree")
            : t("coursesView.accessModalPaid")
        }
      />
      <Element>
        <ImageBox imageUrl={courseData.image ?? ""}>
          <Button variant="secondary" onClick={handleButtonClick}>
            {courseData.isFree
              ? t("buttons.watchForFree")
              : t("buttons.buyAccess")}
          </Button>
        </ImageBox>
        <Flex flexDirection="column" gap="15px" maxWidth="570px">
          <Typography.H5 variant="h5">
            &quot;{courseData.title}&quot;
          </Typography.H5>
          <Typography.P variant="body">{courseData.description}</Typography.P>
          {!courseData.isArchival && (
            <DateBox>
              <Calendar height="16px" />
              <Typography.P variant="body" fontWeight={700} color="secondary">
                {t("coursesView.courseDate")}:
              </Typography.P>
              <Typography.P variant="body" color="secondary">
                {courseData.date}
              </Typography.P>
            </DateBox>
          )}
          <Flex alignItems="center" gridColumnGap={"10px"}>
            <Typography.H6 variant="h6">{t("price")}:</Typography.H6>
            <Typography.H5 variant="h5" color="pink">
              {Number(courseData.price).toFixed(2)}
              {t("zl")}
            </Typography.H5>
          </Flex>
        </Flex>
      </Element>
    </>
  );
};

export default CourseCard;
