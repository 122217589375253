import { FC, useState, useEffect } from "react";
import { Box, Flex, Line, Link, Loader, Typography } from "components";
import { Button, Checkbox, TextField } from "components/_form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { InputWrapper, Wrapper } from "../LoginView";
import { useAccount } from "hooks/useAccount";
import { CourseDetails } from "./CourseDetails";
import { PlanDetails } from "./PlanDetails";
import { createPayment } from "api/payments";
import { toast } from "react-toastify";
import { createTraningParticipant } from "api/trainings";
import { createSubscriber } from "api/subscribers";
import { IRegulation } from "types/regulations";
import { getRegulations } from "api/regulations";

interface IBuyView {
  type: "course" | "plan";
}

export interface IBuyElement {
  id: string;
}

interface IPaymentData {
  id: number;
  description: string;
  price: number;
}

export const BuyView: FC<IBuyView> = ({ type }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useAccount();
  const { id } = useParams();
  const [checkedRegulationIds, setCheckedRegulationIds] = useState<number[]>(
    [],
  );
  const [regulations, setRegulations] = useState<IRegulation[]>();

  const loadRegulations = async () => {
    const { data } = await getRegulations();
    setRegulations(data);
  };

  useEffect(() => {
    loadRegulations();
  }, []);

  const handleCreatePayment = async (paymentData: IPaymentData) => {
    setIsLoading(true);
    try {
      const response = await createPayment({
        userId: userData.id,
        description: paymentData.description,
        unitPrice: paymentData.price,
        trainingParticipantId: paymentData.id,
      });
      if (!response) return;

      if (response.status.statusCode === "SUCCESS") {
        localStorage.setItem("orderId", response.orderId);
        window.location.replace(response.redirectUri);
      }
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscription = async () => {
    setIsLoading(true);
    try {
      const response = await createSubscriber({
        userId: userData.id,
        subscriptionId: Number(id),
      });
      if (!response.data) return;
      const data = {
        id: response.data.id,
        description: response.data.subscription.title,
        price: Number(response.data.subscription.price),
      };
      handleCreatePayment(data);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleParticipant = async () => {
    setIsLoading(true);
    try {
      const response = await createTraningParticipant({
        userId: userData.id,
        trainingId: Number(id),
      });
      if (!response) return;
      const data = {
        id: response.data.id,
        description: response.data.training.title,
        price: Number(response.data.training.price),
      };
      handleCreatePayment(data);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper color="dark" dots>
      <Loader isLoading={isLoading} />
      <InputWrapper>
        <Typography.H3 variant="h3" color="yellow">
          {t("buyView.header")}
        </Typography.H3>

        <Flex flexDirection="column">
          <Flex flexWrap={"nowrap"} flexDirection={"row"} gap="15px">
            <TextField
              label={t("form.firstName.label")}
              placeholder={userData.firstName}
              disabled
            />
            <TextField
              label={t("form.lastName.label")}
              disabled
              placeholder={userData.lastName}
            />
          </Flex>
          <TextField
            type="email"
            label={t("form.email.label")}
            placeholder={userData.email}
            disabled
          />
          <Line />
          {regulations?.map((regulation) => (
            <Box
              display="flex"
              justifyContent="start"
              mb={4}
              alignItems="center"
              key={regulation.id}
            >
              <Checkbox
                checked={checkedRegulationIds.includes(regulation.id)}
                onChange={(newValue) => {
                  const checked = newValue.target.checked;
                  const currentValue = checkedRegulationIds;

                  setCheckedRegulationIds(
                    checked
                      ? currentValue.includes(regulation.id)
                        ? currentValue
                        : [...currentValue, regulation.id]
                      : currentValue.filter((item) => item !== regulation.id),
                  );
                }}
                label={t("form.accepting")}
                labelLink={{
                  text: regulation.name,
                  href: `/info${regulation.link}`,
                }}
              />
            </Box>
          ))}
        </Flex>

        <Box my={3}>
          <Button
            disabled={
              !regulations || regulations.length !== checkedRegulationIds.length
            }
            variant="secondary"
            onClick={() => {
              if (type === "course") {
                handleParticipant();
              } else {
                handleSubscription();
              }
            }}
          >
            {t("buttons.buyAndPay")}
          </Button>
        </Box>
      </InputWrapper>
      <Flex flexDirection="column" width="100%">
        <Typography.H6 variant="h6" color="secondary">
          {t("buyView.yourCart")}
        </Typography.H6>
        <Line />
        {
          {
            course: <CourseDetails id={id ?? ""} />,
            plan: <PlanDetails id={id ?? ""} />,
          }[type]
        }
      </Flex>
    </Wrapper>
  );
};
