import { Box } from "components";
import styled from "styled-components";
import { device } from "styles/theme";

export const GridBox = styled(Box)<{ gap?: string }>`
  display: grid;
  ${({ gap }) => `gap:${gap};`}
  align-items: start;
  grid-template-columns: repeat(2, 1fr);
  @media ${device.tablet} {
    grid-template-columns: 1fr;
    rid-auto-flow: dense;
  }
`;

export const GridBox2 = styled(GridBox)`
  grid-template-columns: repeat(3, 1fr);
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
  }
`;
