import { stringify } from "qs";
import { IPaginationRequest } from "types/pagination";
import {
  IPayment,
  IPayments,
  ICreatePaymentResponse,
  ICreatePaymentRequest,
} from "types/payments";
import request from "./index";

export const getPayments = (
  filters: IPaginationRequest = { page: 1, perPage: 15 },
) =>
  request<IPayments>({
    options: {
      url: `/payments/?${stringify(filters, { arrayFormat: "brackets" })}`,
      method: "get",
    },
  });

export const getPayment = (id: number | string) =>
  request<{ data: IPayment }>({
    options: {
      url: `/payments/${id}`,
      method: "get",
    },
  });

export const createPayment = (payment: ICreatePaymentRequest) =>
  request<ICreatePaymentResponse>({
    options: {
      url: `/pay`,
      method: "post",
      data: {
        payment: {
          ...payment,
          quantity: payment.quantity || 1,
          currency_code: payment.currency_code || "PLN",
        },
      },
    },
  });
