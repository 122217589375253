import { IMessage } from "types/contactMessages";
import { IContact } from "types/forms/contact";
import request from "./index";

export const createContactMessage = (contactMessage: IContact) =>
  request<{ data: IMessage }>({
    options: {
      url: `/contact_messages`,
      method: "post",
      data: {
        contactMessage: {
          title: contactMessage.title,
          from: contactMessage.email,
          message: contactMessage.message,
        },
      },
    },
  });
