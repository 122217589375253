import { Box, Card, Flex } from "components";
import { AccessModal } from "components/AccessModal/AccessModal";
import {
  H1,
  H2,
  H5,
  P,
  TypographyType,
} from "components/Typography/Typography";
import { Button } from "components/_form";
import { ButtonI } from "components/_form/Button/Button";
import { useAccount } from "hooks/useAccount";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "styles/theme";
import { ISubscription } from "types/subscriptions";
import { rgba } from "utilities/rgba";

export interface OfferCardI {
  variant: Variant;
  data: ISubscription;
}

type Variant = "light" | "yellow";

const { palette } = theme;

const bacgroundColorVariantsMap = {
  light: palette.text.secondary,
  yellow: palette.accent.yellow.main,
};

const linesColorVariantsMap = {
  light: palette.accent.pink.main,
  yellow: palette.text.primary,
};

const buttonVariantsMap = {
  light: "secondary",
  yellow: "dark",
};

const priceColorVariantsMap = {
  light: "pink",
  yellow: "primary",
};

const StyledElement = styled(Card)<{ variant: Variant }>`
  text-align: center;
  width: 250px;
  height: max-content;
  border-radius: 5px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: ${({ variant }) => `${bacgroundColorVariantsMap[variant]}`};
`;

const StyledElementHeader = styled(Flex)`
  text-transform: capitalize;
  height: 65px;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${({ theme }) => `
    background-color: ${theme.palette.text.primary};
    color: ${theme.palette.text.secondary};
  `};
`;

const StyledElementContent = styled(Flex)`
  flex-flow: column nowrap;
  gap: 25px;
  width: 100%;
  align-items: center;
  padding: 30px;
`;

const PriceContainer = styled(Flex)`
  flex-flow: row nowrap;
  align-items: baseline;
  gap: 5px;
`;

const DetailsContainer = styled(Flex)`
  flex-flow: column nowrap;
  width: 100%;
`;

const DetailsLine = styled(Box)<{ variant: Variant }>`
  border-bottom: 1px solid
    ${({ variant }) => `${linesColorVariantsMap[variant]}`};
  width: 100%;
  padding: 5px 0px;

  :last-child {
    border-bottom: unset;
  }
`;

export const OfferCard: FC<OfferCardI> = ({ data, variant }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLogged } = useAccount();
  const [openAccessModal, setOpenAccessModal] = useState<boolean>(false);

  const handleClick = () => {
    if (!isLogged) {
      setOpenAccessModal(true);
      return;
    }
    if (data.price === 0) return;
    navigate(`/plans/buy/${data.id}`);
  };

  return (
    <>
      <AccessModal
        isOpen={openAccessModal}
        onCancelClick={() => setOpenAccessModal(false)}
        title={
          data.price === 0
            ? t("plansView.accessModalFree")
            : t("plansView.accessModalPaid")
        }
      />
      <StyledElement variant={variant}>
        <StyledElementHeader>
          <H5 variant="h5" fontWeight={400}>
            {data.title}
          </H5>
        </StyledElementHeader>
        <StyledElementContent>
          <PriceContainer>
            <H2
              variant="h3"
              color={priceColorVariantsMap[variant] as TypographyType["color"]}
            >
              {Number(data.price).toFixed(2)}
            </H2>
            <H5 variant="h5" customColor={rgba(palette.text.primary, 0.5)}>
              {t("currency")}
            </H5>
          </PriceContainer>
          <DetailsContainer>
            <DetailsLine variant={variant}>
              <P variant="body">{data.description}</P>
            </DetailsLine>
          </DetailsContainer>
          <Button
            variant={buttonVariantsMap[variant] as ButtonI["variant"]}
            type="submit"
            onClick={handleClick}
            fullWidth
          >
            {t("buttons.choose")}
          </Button>
        </StyledElementContent>
      </StyledElement>
    </>
  );
};
