import React, { useEffect, useState } from "react";
import { getMe, updateProfile } from "api/user";
import { Flex, Loader, Section } from "components";
import { SectionHeader } from "components/SectionHeader/SectionHeader";
import { H3 } from "components/Typography/Typography";
import { Button } from "components/_form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { IEditUserData } from "types/forms/user";
import { IUser } from "types/user";
import { ChangePasswordModal } from "./components/ChangePasswordModal";
import { ProfileForm } from "./components/ProfileForm";
import { ProfileDetails } from "./components/ProfileDetails";
import { PhotoModal } from "./components/PhotoModal";
import { FILE_URL } from "constants/paths";

export const UserView = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<IUser>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState<string>("");
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [fetchedImages, setFetchedImages] = useState<
    { file: File; originalUrl: string }[]
  >([]);

  const fetchImage = async (url: string) => {
    try {
      const response = await fetch(FILE_URL + url);
      const imageData = await response.blob();

      const urlParts = url.split("/");
      const imageName = urlParts[urlParts.length - 1] || "image.jpg";

      const file = new File([imageData], imageName, {
        type: imageData.type,
      });

      return { file, originalUrl: FILE_URL + url };
    } catch (error) {
      return null;
    }
  };

  const loadImages = async (urls: string[]) => {
    const imagesPromises = urls.map((url) => fetchImage(url));
    const images = await Promise.all(imagesPromises);
    setFetchedImages(
      images.filter((image) => image !== null) as {
        file: File;
        originalUrl: string;
      }[],
    );
  };

  const handlePhotoClick = (url: string) => {
    setSelectedPhotoUrl(url);
    setIsPhotoModalOpen(true);
  };

  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await getMe();
      if (!response.data) return;
      setData(response.data);
      await loadImages(response.data.profile.imagesUrls);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePhoto = async (url: string) => {
    setIsLoading(true);
    try {
      const filteredImages = fetchedImages.filter(
        (imageObj) => imageObj.originalUrl !== FILE_URL + url,
      );

      const filesOnly = filteredImages.map((imageObj) => imageObj.file);

      const response = await updateProfile({
        profile: { images: filesOnly ?? [null] },
      });
      if (!response) return;
      loadData();
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPhoto = async (images: File[]) => {
    setIsLoading(true);
    try {
      const newImages = [...fetchedImages.map(({ file }) => file), ...images];
      const response = await updateProfile({ profile: { images: newImages } });
      if (!response) return;
      loadData();
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (data: IEditUserData) => {
    setIsLoading(true);
    try {
      const response = await updateProfile({ profile: data });
      if (!response) return;
      loadData();
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Loader isLoading={isLoading} />
      {data && (
        <ChangePasswordModal
          isOpen={openPasswordModal}
          onCancelClick={() => setOpenPasswordModal(false)}
          userEmail={data.email}
        />
      )}
      {selectedPhotoUrl && (
        <PhotoModal
          isOpen={isPhotoModalOpen}
          onCancelClick={() => setIsPhotoModalOpen(false)}
          src={selectedPhotoUrl}
          alt="Selected Photo"
        />
      )}
      <SectionHeader>
        <H3 variant="h3" color="primary">
          {t("userDetailsView.header")}
        </H3>
      </SectionHeader>
      <Section color="light" style={{ paddingBlock: "30px" }}>
        {isEditing ? (
          <ProfileForm
            data={data}
            onSubmit={handleUpdate}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            onAddPhoto={handleAddPhoto}
            onPhotoClick={handlePhotoClick}
            handleDeletePhoto={handleDeletePhoto}
          />
        ) : (
          <ProfileDetails data={data} onPhotoClick={handlePhotoClick} />
        )}

        {!isEditing && (
          <>
            <Flex gap="20px" width="100%" justifyContent="center" mt="16px">
              <Button
                variant="secondary"
                textOnly
                label={t("buttons.changePassword")}
                onClick={() => setOpenPasswordModal(true)}
              />
              <Button
                variant="secondary"
                label={t("buttons.editProfile")}
                onClick={() => setIsEditing(true)}
              />
            </Flex>
          </>
        )}
      </Section>
    </>
  );
};
