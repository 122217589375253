import styled from "styled-components";

export const ErrorText = styled.div<{ show?: boolean }>`
  ${({ theme, show }) => `
    margin-top: 2px;
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    text-align: right;
    color: ${show ? theme.palette.error.main : "unset"};
  `};
`;
