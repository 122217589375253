export const device = {
  mobile: `(max-width: 576px)`,
  tablet: `(max-width: 768px)`,
  laptop: `(max-width: 992px)`,
  desktop: `(max-width: 1200px)`,
};

export const theme = {
  palette: {
    accent: {
      pink: {
        main: "#FF00C7",
        light: "#ff60fa",
        dark: "#c70096",
      },
      yellow: {
        main: "#FAFF03",
        light: "#ffff5a",
        dark: "#c3cc00",
      },
      dark: {
        main: "#140f17",
        light: "#3a353d",
        dark: "#000000",
      },
      light: {
        main: "#EFEFEF",
        light: "#FFFFFF",
        mediumDark: "#dddddd",
        dark: "#bdbdbd",
        medium: "#E7E7E7",
      },
    },
    neutral: {
      white: "#FFFFFF",
      black: "#000000",
    },
    text: {
      primary: "#140F17",
      secondary: "#FFFFFF",
      grey: "#bababa",
      lightGrey: "#c5c5c5",
    },
    error: {
      main: "#DF3939",
      light: "#ff6e63",
      dark: "#a40011",
    },
    success: {
      main: "#44AF1E",
    },
    background: {
      navbar: "#140F17",
      dark: "#17121A",
      light: "#F2F2F2",
      medium: "#FCFCFC",
    },
  },
  textStyles: {
    button: {
      fontSize: 16,
      fontWeight: 700,
    },
    helperText: {
      fontSize: 12,
      fontStyle: "italic",
    },
    body3: {
      fontSize: 18,
    },
    body2: {
      fontSize: 14,
    },
    body: {
      fontSize: 16,
    },
    h6: {
      fontSize: 24,
      fontWeight: 700,
    },
    h5: {
      fontSize: 32,
      lineHeight: "24px",
      fontWeight: 700,
    },
    h4: {
      fontSize: 40,
      lineHeight: "30px",
      fontWeight: 700,
    },
    h3: {
      fontSize: 48,
      lineHeight: "36px",
      fontWeight: 700,
    },
    h2: {
      fontSize: 64,
      lineHeight: "58px",
      fontWeight: 700,
    },
    h1: {
      fontSize: 72,
      lineHeight: "60px",
      fontWeight: 700,
    },
  },
};
