import { JitsiMeeting } from "@jitsi/react-sdk";
import { getTraining } from "api/trainings";
import { Flex, Section, Typography } from "components";
import { useAccount } from "hooks/useAccount";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ITraining } from "types/trainings";

const StyledSection = styled(Section)`
  padding-block: 40px;
  width: 100%;
  display: flex;
  width: 100%;
  flex-flow: column;
  gap: 15px;
  align-items: center;
`;

export const CourseDetailsView = () => {
  const { isLogged, userData } = useAccount();
  const { id } = useParams();
  const [courseDetails, setCourseDetails] = useState<ITraining>();

  const loadCourseData = async () => {
    const { data } = await getTraining(Number(id));
    setCourseDetails(data);
  };

  useEffect(() => {
    loadCourseData();
  }, []);

  return (
    <StyledSection color="light">
      {courseDetails ? (
        <>
          <Flex flexDirection="column" alignItems="start" width="100%">
            <Typography.H5 variant="h5">
              &quot;{courseDetails?.title}&quot;
            </Typography.H5>
            <Typography.P variant="body">
              {courseDetails?.description}
            </Typography.P>
          </Flex>

          {courseDetails?.room.active ? (
            <JitsiMeeting
              roomName={courseDetails?.room.name ?? ""}
              userInfo={
                isLogged
                  ? { displayName: userData!.firstName, email: userData!.email }
                  : undefined
              }
              getIFrameRef={(iframeRef) => {
                iframeRef.style.height = "780px";
                iframeRef.style.width = "100%";
              }}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <Typography.H3 variant="h3" color="primary">
          Szkolenie tymczasowo niedostępne
        </Typography.H3>
      )}
    </StyledSection>
  );
};
