const common = {
  privacyPolicy: "privacy policy",
  regulamin: "regulamin",
  first_name: "first name",
  last_name: "last name",
};

const auth = {
  login: "login",
  register: "register",
  password: "password",
  forgotPass: "Forgot password?",
  accountless: "Don't have account?",
  emailRequired: "Email is required",
  badEmailFormat: "Invalid email format",
  passwordRequired: "Password is required",
  passMinVal: "The password should contain at least 6 characters",
  passMaxVal: "The password can be up to 18 characters long",
  passDiffErr: "The passwords do not match",
  acceptRegulamin: "Accept the terms and conditions",
  password_confirmation: "password confirmation",
  send_email: "Send e-mail",
};

const userDetailsView = {
  header: "User Details",

  firstName: {
    label: "First Name:",
    placeholder: "Your first name...",
  },
  lastName: {
    label: "Last Name:",
    placeholder: "Your last name...",
  },
  email: {
    label: "Email:",
    placeholder: "Your email...",
  },
  dimensions: {
    label: "Dimensions:",
    placeholder: "Your dimensions...",
  },
  weight: {
    label: "Weight:",
    placeholder: "Your weight...",
  },
  waterConsumption: {
    label: "Water Consumption:",
    placeholder: "Your daily water intake...",
  },
  caloriesConsumption: {
    label: "Calories Consumption:",
    placeholder: "Your daily calorie intake...",
  },
  supplementsConsumption: {
    label: "Supplements Consumption:",
    placeholder: "Your supplements...",
  },
  description: {
    label: "Description:",
    placeholder: "Add a description...",
  },
  activities: {
    label: "Activities:",
    placeholder: "Your daily activities...",
  },
};

export default {
  ...common,
  ...auth,
  userDetailsView,
};
