import { IExercise, IExerciseVideo } from "types/exercises";
import request from "./index";
import { AxiosResponse } from "axios";

export const getExercise = (id: number | string) =>
  request<{ data: IExercise }>({
    options: {
      url: `/exercises/${id}`,
      method: "get",
    },
  });

export const getExerciseVideo = (id: number | string) =>
  request<{ data: IExerciseVideo }>({
    options: {
      url: `/exercise_videos/${id}`,
      method: "get",
    },
  });

export const getExerciseVideoFileUrl = (url: string) =>
  request<AxiosResponse>({
    options: {
      url,
      method: "get",
    },
    handleSubmit: (response: AxiosResponse) => {
      return response;
    },
  });
