import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import { AppLayout } from "layouts/App/AppLayout";
import { Outlet, RouteObject } from "react-router-dom";
import { RegisterView } from "./views/RegisterView";
import { LoginView } from "./views/LoginView";
import { ResetPasswordView } from "./views/ResetPasswordView";
import { HomeView } from "views/HomeView/HomeView";
import { CoursesView } from "views/CoursesView/CoursesView";
import { ExercisesAtlas } from "views/ExercisesAtlas/ExercisesAtlas";
import { BuyView } from "views/BuyView/BuyView";
import { PlansView } from "views/PlansView/PlansView";
import { TransactionReturnView } from "views/TransactionReturnView";
import { ConfirmEmailView } from "views/ConfirmEmailView";
import { CustomView } from "views/CustomView";
import { CourseDetailsView } from "views/CoursesView/CourseDetailsView";
import { AboutUsView } from "views/AboutContactView/AboutUsView";
import { ContactView } from "views/AboutContactView/ContactView";
import { UserView } from "views/UserView/UserView";
import { NotFoundView } from "views/NotFoundView";
import { ExerciseDetailsView } from "views/ExercisesAtlas/ExerciseDetailsView";
import { OrdersView } from "views/OrdersView/OrdersView";
import { OrderDetailsView } from "views/OrdersView/OrderDetailsView";
import { NewPasswordView } from "views/NewPasswordView";
import EUInfoView from "views/EUInfoView";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: "/",
        element: <HomeView />,
      },
      {
        path: "/login",
        element: <LoginView />,
      },
      {
        path: "/register",
        element: <RegisterView />,
      },
      {
        path: "/password-reset",
        element: <ResetPasswordView />,
      },
      {
        path: "/new-password",
        element: <NewPasswordView />,
      },
      {
        path: "/login-confirm",
        element: <ConfirmEmailView />,
      },
      {
        path: "/transaction",
        element: <TransactionReturnView />,
      },
      {
        path: "/about-us",
        element: <AboutUsView />,
      },
      {
        path: "/contact",
        element: <ContactView />,
      },
    ],
  },
  {
    path: "/plans",
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: "",
        element: <PlansView />,
      },
      {
        path: "buy/:id",
        element: (
          <ProtectedRoute>
            <BuyView type="plan" />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/exercises-atlas",
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: "",
        element: <ExercisesAtlas />,
      },
      {
        path: ":id",
        element: (
          <ProtectedRoute>
            <ExerciseDetailsView />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/courses",
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: "",
        element: <CoursesView />,
      },
      {
        path: "archivals",
        element: <CoursesView isArchival />,
      },
      {
        path: ":id",
        element: <CourseDetailsView />,
      },
      {
        path: "buy/:id",
        element: (
          <ProtectedRoute>
            <BuyView type="course" />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/info",
    element: (
      <AppLayout>
        <Outlet />
      </AppLayout>
    ),
    children: [
      {
        path: ":slug",
        element: <CustomView />,
      },
      {
        path: "eu-info",
        element: <EUInfoView />,
      },
    ],
  },
  {
    path: "/user",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <UserView />,
      },
    ],
  },
  {
    path: "/orders",
    element: (
      <ProtectedRoute>
        <AppLayout>
          <Outlet />
        </AppLayout>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "",
        element: <OrdersView />,
      },
      {
        path: ":id",
        element: <OrderDetailsView />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <AppLayout>
        <NotFoundView />
      </AppLayout>
    ),
  },
];
