import { Card } from "components/Card";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import React, { CSSProperties, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { rgba } from "utilities/rgba";
import { Box } from "components/Box";
import { ReactComponent as CrossIcon } from "assets/icons/bx-x.svg";

const modalRoot = document.getElementById("modal-root")!;

export interface IModal {
  isOpen: boolean;
  onCancelClick: () => void;
  disableBackdropClick?: boolean;
  styles?: CSSProperties;
  cardStyles?: CSSProperties;
  children?: React.ReactNode;
  showCloseButton?: boolean;
}

const WrapperModal = styled.div`
  ${({ theme }) => `
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${rgba(theme.palette.neutral.black, 0.5)};
    width: 100vw;
    height: 100vh;
    padding-inline: 20px;
    touch-action: none;
  `}
`;

const StyledCard = styled(Card)`
  max-height: 100vh;
  overflow: auto;
  margin: auto;
  border-radius: 15px;
  max-width: 600px;
  text-align: center;
  position: relative;
`;

const CloseButton = styled(Box)`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
`;

export const Modal: React.FC<IModal> = ({
  isOpen,
  onCancelClick,
  styles,
  cardStyles,
  children,
  disableBackdropClick,
  showCloseButton = true,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [isOpen]);

  useOnClickOutside(wrapperRef, () => {
    if (!disableBackdropClick) onCancelClick();
  });

  return ReactDOM.createPortal(
    <>
      {isOpen ? (
        <WrapperModal style={styles}>
          <div ref={wrapperRef}>
            <StyledCard style={cardStyles}>
              {showCloseButton && (
                <CloseButton aria-label="Close" onClick={onCancelClick}>
                  <CrossIcon />
                </CloseButton>
              )}
              {children}
            </StyledCard>
          </div>
        </WrapperModal>
      ) : null}
    </>,
    modalRoot,
  );
};
