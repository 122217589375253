import { Flex } from "components";
import { Button } from "components/_form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ITableButtons {
  id: string;
  isPaid: boolean;
}

export const TableButtons = ({ id, isPaid }: ITableButtons) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePay = () => {
    console.log(id);
  };

  const handleShowDetails = () => {
    navigate(`/orders/${id}`);
  };

  return (
    <Flex gap="15px">
      <Button
        variant="secondary"
        label={t("buttons.pay")}
        disabled={isPaid}
        onClick={handlePay}
      />
      <Button
        variant="dark"
        label={t("buttons.seeDetails")}
        onClick={handleShowDetails}
      />
      <Button textOnly variant="dark" label={t("buttons.cancel")} />
    </Flex>
  );
};
