import { Flex, Section, SectionHeader } from "components";
import { H3, P } from "components/Typography/Typography";
import styled from "styled-components";
import EUflags from "assets/images/eu-logos.png";

const Wrapper = styled(Section)`
  padding-block: 20px;
`;

export const EUImage = styled.img`
  border-radius: 5px;
  object-fit: contain;
  max-width: 600px;
  width: 100%;
  height: auto;
`;

const EUInfoView = () => {
  return (
    <>
      <SectionHeader>
        <H3 variant="h3">Bon na cyfryzację</H3>
      </SectionHeader>
      <Wrapper color="light">
        <Flex flexDirection="column" gap="20px">
          <P variant="h6" mb="20px" textAlign="center">
            Ogólne informacje:
          </P>
          <Flex gap="5px" alignItems="baseline">
            <P variant="body3" fontWeight={700}>
              Program operacyjny:
            </P>
            <P variant="body">Program Operacyjny Inteligentny Rozwój</P>
          </Flex>
          <Flex gap="5px" alignItems="baseline">
            <P variant="body3" fontWeight={700}>
              Oś Priorytetowa:
            </P>
            <P variant="body">
              6 Zwiększenie potencjału przedsiębiorstw i przygotowanie do
              cyfrowej transformacji w kontekście pandemii COVID-19
            </P>
          </Flex>
          <Flex gap="5px" alignItems="baseline">
            <P variant="body3" fontWeight={700}>
              Działanie:
            </P>
            <P variant="body">
              6.2 Wsparcie MŚP w obszarze cyfryzacji - Bony na cyfryzację
            </P>
          </Flex>
          <Flex gap="5px" alignItems="baseline">
            <P variant="body3" fontWeight={700}>
              Cel projektu:
            </P>
            <P variant="body">
              Wdrożenie technologii cyfrowych w celu dostosowania procesów i
              sposobu funkcjonowania przedsiębiorstwa do warunków epidemii
              chorób zakaźnych, zwłaszcza pandemii COVID-19, oraz zapewnienie
              bezpieczeństwa i dystansu społecznego w sektorze fitness.
            </P>
          </Flex>
          <Flex gap="5px" alignItems="baseline">
            <P variant="body3" fontWeight={700}>
              Efekty projektu:
            </P>
            <P variant="body">
              wdrożenie innowacyjnego systemu elektronicznej obsługi klienta,
              implementację pracy zdalnej, poprawę bezpieczeństwa i dystansu
              społecznego w sektorze fitness, utrzymanie aktywności klientów za
              pomocą aplikacji treningowych oraz przygotowanie przedsiębiorstwa
              na przyszłe wyzwania związane z epidemią chorób zakaźnych.
            </P>
          </Flex>
          <P variant="h6" my="20px" textAlign="center">
            Beneficjent:
          </P>
          <P variant="body">ZDYBKA - KATARZYNA ZDYB</P>
          <Flex gap="5px" alignItems="baseline">
            <P variant="body3" fontWeight={700}>
              Całkowita wartość projektu:
            </P>
            <P variant="body">299 300.00 PLN Brutto</P>
          </Flex>
          <Flex gap="5px" alignItems="baseline">
            <P variant="body3" fontWeight={700}>
              Wartość dofinansowania:
            </P>
            <P variant="body">254 405.00 PLN</P>
          </Flex>
          <Flex gap="5px" alignItems="baseline">
            <P variant="body3" fontWeight={700}>
              Okres realizacji:
            </P>
            <P variant="body">01/06/2022 - 31/05/2023</P>
          </Flex>
          <P variant="body" textAlign="center" mt="20px">
            Sfinansowano w ramach reakcji Unii na pandemię COVID 19, realizujemy
            projekt ze wsparciem z REACT-EU W RAMACH DZIAŁANIA 6.2 WSPARCIE MŚP
            W OBSZARZE CYFRYZACJI - BONY NA CYFRYZACJĘ W RAMACH PROGRAMU
            OPERACYJNEGO INTELIGENTNY ROZWÓJ 2014-2020
          </P>
          <Flex width="100%" justifyContent="center">
            <EUImage src={EUflags} alt="EU project logo" />
          </Flex>
        </Flex>
      </Wrapper>
    </>
  );
};

export default EUInfoView;
