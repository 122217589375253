export const navItems = [
  {
    label: "navBar.aboutUs",
    path: "/about-us",
    private: false,
  },
  {
    label: "navBar.courses",
    path: "/courses",
    private: false,
  },
  {
    label: "navBar.exercisesAtlas",
    path: "/exercises-atlas",
    private: false,
  },
  {
    label: "navBar.priceList",
    path: "/plans",
    private: false,
  },
  {
    label: "navBar.contact",
    path: "/contact",
    private: false,
  },
];
