import React from "react";
import ContactImage from "assets/images/contactImage.png";
import ContactPhoto from "assets/images/contactPhoto.png";
import {
  BackgroundImage,
  BottomSection,
  Header,
  HeaderContainer,
  HeaderShadow,
  Image,
  UpperSection,
} from "./components/AboutContractView.styled";
import { Box } from "components";
import styled from "styled-components";
import { device } from "styles/theme";
import { ContactForm } from "./components/ContactForm";
import { useTranslation } from "react-i18next";

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 120px;
  padding-block: 40px;

  @media ${device.laptop} {
    grid-template-columns: 1fr;
    gap: 60px;
  }
  justify-items: center;
  align-items: center;
  .form {
    justify-self: start;
    align-self: end;
  }
`;

export const ContactView = () => {
  const { t } = useTranslation();

  return (
    <>
      <BackgroundImage imageUrl={ContactImage}>
        <UpperSection color="transparent">
          <HeaderContainer color="yellow">
            <Header>{t("contactView.header")}</Header>
            <HeaderShadow>{t("contactView.header")}</HeaderShadow>
          </HeaderContainer>
        </UpperSection>
      </BackgroundImage>
      <BottomSection color="light">
        <Grid>
          <Image src={ContactPhoto} />
          <ContactForm className="form" />
        </Grid>
      </BottomSection>
    </>
  );
};
