import {
  ITraining,
  ITrainingFilters,
  ITrainingParticipant,
  ITrainingParticipantCreate,
  ITrainingsResponse,
} from "types/trainings";
import request from "./index";
import { stringify } from "qs";

export const getTrainings = (filters: ITrainingFilters) =>
  request<ITrainingsResponse>({
    options: {
      url: `/trainings/?${stringify(filters, { arrayFormat: "brackets" })}`,
      method: "get",
    },
  });

export const getTraining = (id: number | string) =>
  request<{ data: ITraining }>({
    options: {
      url: `/trainings/${id}`,
      method: "get",
    },
  });

export const createTraningParticipant = (
  trainingParticipant: ITrainingParticipantCreate,
) =>
  request<{ data: ITrainingParticipant }>({
    options: {
      url: `/training_participants`,
      method: "post",
      data: {
        trainingParticipant: trainingParticipant,
      },
    },
  });
