import { Flex, Loader, Section, SectionHeader, Typography } from "components";
import { Button } from "components/_form";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Calendar } from "assets/icons/ico-calendar.svg";
import CourseCard from "./components/CourseCard";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination } from "components";
import { getTrainings } from "api/trainings";
import moment from "moment";
import { dateFormat } from "constants/dateFormats";
import { toast } from "react-toastify";
import { ITraining } from "types/trainings";
import { IPaginationResponse } from "types/pagination";

interface ICourses {
  isArchival?: boolean;
}

const ElementsWrapper = styled(Flex)`
  padding-block: 45px;
  flex-flow: column nowrap;
  width: 100%;
  gap: 45px;
  align-items: center;
`;

export const CoursesView = ({ isArchival = false }: ICourses) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [coursesDetails, setCoursesDetails] = useState<ITraining[]>();
  const [paginationDetails, setPaginationDetails] =
    useState<IPaginationResponse>({
      current: 1,
      previous: null,
      next: null,
      pages: 1,
      count: 1,
      perPage: 5,
    });

  const loadCoursesData = async () => {
    setIsLoading(true);
    try {
      const { data, pagination } = await getTrainings({
        page: page,
        perPage: paginationDetails.perPage,
        archived: isArchival,
      });
      setCoursesDetails(data);
      setPaginationDetails(pagination);
    } catch (e: any) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCoursesData();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page, isArchival]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <SectionHeader>
        <Typography.H3 variant="h3" color="primary">
          {isArchival
            ? t("coursesView.archivalHeader")
            : t("coursesView.upcomingHeader")}
        </Typography.H3>

        <Button
          variant="dark"
          icon={<Calendar />}
          onClick={() =>
            navigate(isArchival ? "/courses" : "/courses/archivals")
          }
        >
          {!isArchival
            ? t("coursesView.archivalHeader")
            : t("coursesView.upcomingHeader")}
        </Button>
      </SectionHeader>
      <Section color="light">
        <ElementsWrapper>
          {coursesDetails &&
            coursesDetails.map((e, k) => {
              return (
                <CourseCard
                  courseData={{
                    id: e.id,
                    title: e.title,
                    description: e.description,
                    date: `${moment(e.startAt).format(dateFormat)} - ${moment(
                      e.finishAt,
                    ).format(dateFormat)}`,
                    price: e.price ?? "",
                    image: e.thumbnail,
                    isArchival: e.archived,
                    isFree: Number(e.price) === 0,
                  }}
                  key={k}
                />
              );
            })}
        </ElementsWrapper>
        {paginationDetails.pages > 1 && (
          <Pagination
            actualPage={page}
            totalPages={paginationDetails.pages}
            setActualPage={setPage}
          />
        )}
      </Section>
    </>
  );
};
