import React, { useState } from "react";
import { Flex, Box, Menu, Section, Link, Dropdown } from "components";
import styled from "styled-components";
import { Squash as Hamburger } from "hamburger-react";
import { Button } from "components/_form";
import { useNavigate } from "react-router";
import { device, theme } from "styles/theme";
import { navItems } from "constants/navItems";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import LogoImage from "assets/images/logo.png";
import { useWindowWidth } from "hooks/useWindowWidth";
import { logout } from "api/authApi";
import { useAccount } from "hooks/useAccount";
import { P } from "components/Typography/Typography";
import { ReactComponent as Profile } from "assets/icons/ico-profile.svg";

const Wrapper = styled(Section)`
  z-index: 100;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: fit-content;
  padding-block: 25px;

  @media ${device.tablet} {
    flex-flow: column nowrap;
    padding: 10px 25px;
  }
`;

const Logo = styled.img`
  max-height: 110px;
  width: auto;
  object-fit: contain;
  @media ${device.tablet} {
    height: 60px;
  }
`;

const StyledMenuIcon = styled(Box)`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

const SolidWrapper = styled(Flex)`
  @media ${device.tablet} {
    width: 100%;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
`;

const AnimationWrapper = styled.div`
  @media ${device.tablet} {
    width: 100%;
  }
`;

const NavigationContent = styled(Flex)`
  flex-flow: column wrap;
  justify-content: space-between;

  @media ${device.tablet} {
    ${({ isOpen }: { isOpen: boolean }) =>
      isOpen ? "margin-top: 0;" : "margin-top: -100%;"};
    width: 100%;
    flex-direction: column-reverse;
  }
  transition: all 1s;
`;

const ButtonContainer = styled(Flex)`
  flex-flow: row wrap;
  width: 100%;
  justify-content: end;
  gap: 16px;
  margin-block: 16px;
  @media ${device.tablet} {
    justify-content: center;
  }
`;

export const Navbar: React.FC = () => {
  const { t } = useTranslation();

  const windowWidth = useWindowWidth();

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { isLogged, userData } = useAccount();

  const navigate = useNavigate();
  const location = useLocation();

  const dropdownItems = [
    { label: t("dropdown.orders"), onClick: () => navigate("/orders") },
    { label: t("dropdown.userDetails"), onClick: () => navigate("/user") },
    {
      label: t("dropdown.logOut"),
      onClick: async () => {
        try {
          const response = await logout();
          navigate("/login");
          setIsMenuOpened((prev) => !prev);
        } catch (e: any) {
        } finally {
        }
      },
    },
  ];

  return (
    <Wrapper color={location.pathname === "/" ? "transparent" : "navbar"}>
      <SolidWrapper>
        <Link to={"/"}>
          <Logo tabIndex={0} src={LogoImage} alt="Logo FitZdybka" />
        </Link>
        <StyledMenuIcon>
          <Hamburger
            rounded
            toggled={isMenuOpened}
            toggle={setIsMenuOpened}
            color={theme.palette.text.secondary}
          />
        </StyledMenuIcon>
      </SolidWrapper>

      <AnimationWrapper>
        <NavigationContent isOpen={isMenuOpened}>
          <ButtonContainer>
            {isLogged ? (
              <Dropdown items={dropdownItems}>
                <Flex alignItems="center" gap="10px">
                  <P variant="body" color="yellow">
                    {userData.email}
                  </P>
                  <Profile />
                </Flex>
              </Dropdown>
            ) : (
              <>
                <Button
                  type="submit"
                  onClick={() => {
                    navigate("/register");
                    setIsMenuOpened((prev) => !prev);
                  }}
                  textOnly
                >
                  {t("buttons.join")}
                </Button>

                <Button
                  type="submit"
                  onClick={() => {
                    navigate("/login");
                    setIsMenuOpened((prev) => !prev);
                  }}
                >
                  {t("buttons.signIn")}
                </Button>
              </>
            )}
          </ButtonContainer>
          <Box mr={[0, -3]}>
            <Menu
              view={windowWidth > 768 ? "vertical" : "horizontal"}
              onMenuIconClick={() => {
                setIsMenuOpened((prev) => !prev);
              }}
              items={navItems}
            />
          </Box>
        </NavigationContent>
      </AnimationWrapper>
    </Wrapper>
  );
};
