const common = {
  privacyPolicy: "polityka prywatności",
  regulamin: "regulamin",
  first_name: "imię",
  last_name: "nazwisko",
  search: "Wyszukaj",
  currency: "PLN",
  zl: "zł",
  price: "cena",
  send: "Wyślij",
  close: "Zamknij",
};

const navBar = {
  aboutUs: "o nas",
  courses: "szkolenia",
  exercisesAtlas: "atlas ćwiczeń",
  priceList: "cennik",
  contact: "kontakt",
};

const homePage = {
  tempText: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
  tempText2: "Viverra tellus in hac habitasse platea",
  banners: {
    banner1: "Innowacyjna platforma treningowa",
    banner2: "Innowacyjna platforma treningowa",
    banner3: "Innowacyjna platforma treningowa",
  },
  second: {
    title: "Co możemy Ci zaoferować?",
    bullet1: "Szkolenia online",
    bullet2: "Dostęp do nagrań z historycznych szkoleń",
    bullet3: "Ćwiczenia podzielone na kategorie",
    bullet4: "Filmy pomagające w poprawnym wykonywaniu ćwiczeń",
    bullet5: "Atrakcyjne ceny pakietów",
    bullet6: "Możliwość kontaktu poprzez formularz",
  },
};

const cardTitle = {
  onlineTraining: "trening online",
  personalTraining: "trening personalny",
};

const buttons = {
  findMore: "Dowiedz się wiecej >",
  choose: "Wybieram",
  join: "Dołącz",
  createAccount: "Utwórz konto",
  signIn: "Zaloguj się",
  singUp: "Zarejestruj się",
  buyAccess: "Kup dostęp",
  watchForFree: "Obejrzyj za darmo",
  buyAndPay: "Kupuję i płacę",
  tryAgain: "Ponów próbę",
  backToHomeView: "Wróć na stronę główną",
  welcome: "Witaj",
  send: "Wyślij",
  forgotPassword: "Nie pamiętasz hasła?",
  saveChanges: "Zapisz zmiany",
  pay: "Zapłać",
  seeDetails: "Zobacz szczegóły",
  cancel: "Anuluj",
  cancelOrder: "Anuluj zamówienie",
  changePassword: "Zmień hasło",
  editProfile: "Edytuj profil",
};

const footer = {
  openingHours: "godziny otwarcia",
  findMeOn: "znajdz mnie na",
  newsletter: "newsletter",
  copyright: "Copyrights © 2022. All rights reserved.",
  signUpToNewsletter: "Zapisz się do newslettera",
};

const auth = {
  forgotPass: "Zapomniałeś hasła?",
  accountless: "Nie masz konta?",
  accountAlready: "Masz już konto?",
  notAccountAlready: "Nie masz konta?",
  emailRequired: "Email jest wymagany",
  badEmailFormat: "Niepoprawny format email",
  passwordRequired: "Hasło jest wymagane",
  passMinVal: "Hasło powinno zawierać przynajmniej 6 znaków",
  passMaxVal: "Hasło może zawierać maksymalnie 18 znaków",
  passDiffErr: "Hasła nie są takie same",
  acceptRegulamin: "Zaakceptuj regulamin",
  password_confirmation: "potwierdzenie hasła",
  send_email: "Wyślij e-mail",
  set_new_password: "Ustaw nowe hasło",
  confirm_send_reset_email:
    "Czy na pewno chcesz wysłać prośbę o zresetowanie hasła na adres: ",
  success_message: "Prośba została wysłana",
};

const registerView = {
  header: "Dołącz do nas!",
};

const loginView = {
  header: "Zaloguj się!",
};

const coursesView = {
  upcomingHeader: "Kalendarz szkoleń",
  archivalHeader: "Szkolenia archiwalne",
  accessModalPaid: "Zaloguj się, aby móc wykupić dostęp do szkolenia",
  accessModalFree: "Zaloguj się, aby móc obejrzeć to szkolenie za darmo",
  courseDate: "Data szkolenia",
};

const plansView = {
  accessModalFree: "Zaloguj się, aby mieć dostęp do darmowych treści",
  accessModalPaid: "Zaloguj się, aby móc zakupić wybrany plan",
};

const exercisesAtlasView = {
  exercisesAtlas: "Atlas ćwiczeń",
  accessModalPaid: "Zaloguj się, aby móc wykupić dostęp do ćwiczenia",
  accessModalFree: "Zaloguj się, aby móc obejrzeć to ćwiczenie za darmo",
  seeMoreInCategory: "Zobacz więcej w kategorii",
  whatAreWeTraining: "Co dzisiaj trenujemy?",
  clickOnTheMusclePart:
    " Kliknij na interesujacą Cię partię mięśni, a my dobierzemy dla ciebie odpowiedni zestaw ćwiczeń 😊",
  chooseMusclePart:
    "Zaznacz interesującą Cię partie mięśni, które chcesz ćwiczyć 😊",
  whatWillYouFindinVideos: "Co znajdziesz na filmach?",
  points: {
    point1: "Ćwiczenia odpowiednie do warunków domowych",
    point2: "Trening 12 partii ciała",
    point3: "Poznasz 4 doświadczonych trenerów personalnych",
    point4: "Motywację i inspirację",
    point5: "Edukację i wiedzę",
  },
  training: "Trening",
  trainingType: {
    chest: "klatki piersiowej",
    shoulders: "barków",
    biceps: "bicepsów",
    triceps: "tricepsów",
    back: "pleców",
    quadriceps: "mięśni czterogłowych uda",
    hamstrings: "mięśni dwógłowych uda",
    calves: "łydek",
    buttocks: "pośladków",
    stomach: "brzucha",
  },
  chooseTraining: "Wybierz trening",
  noExercises: "Brak ćwiczeń dla tej partii ciała",
  extraInfo:
    "*Ważne jest, aby pamiętać o wykonywaniu ćwiczeń prawidłowo i w odpowiedniej formie. Jeśli jesteś początkujący lub masz jakiekolwiek problemy zdrowotne, zawsze warto skonsultować się z trenerem lub specjalistą przed rozpoczęciem nowego programu treningowego. 🙂",
};

const muscle = {
  frontSide: "Przód",
  backSide: "Tył",
  chest: "Klatka",
  stomach: "Brzuch",
  shoulders: "Barki",
  biceps: "Bicepsy",
  quadriceps: "Czterogłowe ud",
  back: "Plecy",
  hamstrings: "Dwugłowe ud",
  calves: "Łydki",
  buttocks: "Pośladki",
  triceps: "Tricepsy",
};

const buyView = {
  header: "Formularz zamówienia",
  yourCart: "Twój koszyk:",
  price: "cena:",
};

const aboutUsView = {
  header: "O nas",
};

const contactView = {
  header: "Kontakt",
  writeToUs: "Napisz do nas",
  fillForm: "Wypełnij poniższy formularz:",
};

const transactionReturnView = {
  thankYou: "Dziękujemy",
  somethingWrong: "Coś poszło nie tak",
  successHeader: "Twoja płatność powiodła się",
  failedHeader: "Twoja płatność nie powiodła się",
  moreInformationOnMail:
    "Na podany adres mailowy otrzymasz szczegóły zamówienia",
};

const notFoundView = {
  title: "Strona, której szukasz, nie istnieje.",
};

const form = {
  rulesCheckbox: {
    label: "Przeczytałem/am i akceptuję regulamin",
  },
  email: {
    label: "Adres email:",
    placeholder: "Podaj adres email...",
  },
  password: {
    label: "Hasło:",
    placeholder: "Podaj hasło...",
  },
  repeatPassword: {
    label: "Powtórz hasło:",
    placeholder: "Podaj ponownie hasło...",
  },
  firstName: {
    label: "Imię:",
    placeholder: "Podaj swoje imię...",
  },
  lastName: {
    label: "Nazwisko:",
    placeholder: "Podaj swoje nazwisko...",
  },
  paymentMethod: {
    label: "Metoda płatności:",
    placeholder: "Wybierz metodę płatności...",
  },
  title: {
    label: "Tytuł wiadomości:",
    placeholder: "Wpisz tytuł...",
  },
  message: {
    label: "Treść wiadomości:",
    placeholder: "Wpisz swoją wiadomość",
  },
  password_confirmation: {
    label: "Powtórz hasło:",
    placeholder: "Podaj ponownie hasło...",
  },
  accepting: "Akceptuję",
};

const validation = {
  acceptRules: "Akceptacja regulaminu jest wymagana",
  fieldIsRequired: "Pole jest wymagane",
  emailRequired: "Email jest wymagany",
};

const dropdown = {
  orders: "Zamówienia",
  userDetails: "Szczegóły konta",
  logOut: "Wyloguj",
};

const ordersView = {
  header: "Zamówienia",
  table: {
    productName: "Nazwa produktu",
    status: "Status",
    date: "Data",
    totalAmount: "Łączna kwota",
    subscription: "Subskrypcja",
  },
  paid: "Opłacony",
  unpaid: "Nieopłacony",
};

const userDetailsView = {
  header: "Dane użytkownika",
  aboutMe: "Informacje o mnie:",

  firstName: {
    label: "Imię:",
    placeholder: "Twoje imię...",
  },
  lastName: {
    label: "Nazwisko:",
    placeholder: "Twoje nazwisko...",
  },
  email: {
    label: "E-mail:",
    placeholder: "Twój adres e-mail...",
  },
  dimensions: {
    label: "Wymiary:",
    placeholder: "Twoje wymiary...",
  },
  weight: {
    label: "Waga:",
    placeholder: "Twoja waga...",
  },
  waterConsumption: {
    label: "Spożycie wody:",
    placeholder: "Twoje dzienne spożycie wody...",
  },
  caloriesConsumption: {
    label: "Spożycie kalorii:",
    placeholder: "Twoje dzienne spożycie kalorii...",
  },
  supplementsConsumption: {
    label: "Spożycie suplementów:",
    placeholder: "Twoje suplementy, które spożywasz...",
  },
  description: {
    label: "Opis:",
    placeholder: "Dodaj opis o sobie...",
  },
  activities: {
    label: "Aktywności:",
    placeholder: "Twoje codzienne aktywności...",
  },
  photos: {
    label: "Zdjęcia:",
    placeholder: "Twoje zdjęcia...",
  },
};

export default {
  ...common,
  ...auth,
  cardTitle,
  navBar,
  homePage,
  form,
  validation,
  footer,
  buttons,
  registerView,
  loginView,
  coursesView,
  plansView,
  exercisesAtlasView,
  buyView,
  transactionReturnView,
  contactView,
  userDetailsView,
  aboutUsView,
  notFoundView,
  dropdown,
  ordersView,
  muscle,
};
