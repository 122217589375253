import { FC, useEffect, useState } from "react";
import { Box, Flex, Link, Loader, Typography } from "components";
import { Button, Checkbox, TextField } from "components/_form";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerSchema } from "validation/authSchemas";
import { IRegister } from "types/forms/auth";
import { register as registerRec } from "../api/authApi";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Image, InputWrapper, Wrapper } from "./LoginView";
import RegisterImage from "assets/images/registerBanner.png";
import { Flatten } from "types/helper-types";
import { getRegulations } from "api/regulations";
import { IRegulation } from "types/regulations";

export const RegisterView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [regulations, setRegulations] = useState<IRegulation[]>();
  const [requiredRegulationsChecked, setRequiredRegulationsChecked] =
    useState(false);

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    formState: { errors },
  } = useForm<Flatten<IRegister>>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      passwordConfirmation: "",
      firstName: "",
      lastName: "",
      regulationIds: [],
    },
    resolver: yupResolver(registerSchema),
  });

  useEffect(() => {
    if (regulations) {
      const requiredRegulationIds = regulations
        .filter((regulation) => regulation.required)
        .map((regulation) => regulation.id);
      const checkedRegulationIds = watch("regulationIds");
      const allRequiredChecked = requiredRegulationIds.every((id) =>
        checkedRegulationIds.includes(id),
      );
      setRequiredRegulationsChecked(allRequiredChecked);
    }
  }, [regulations, watch("regulationIds")]);

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    const { firstName, lastName, ...rest } = data;

    const payload = { ...rest, profileAttributes: { firstName, lastName } };

    try {
      const response = await registerRec(payload);
      if (!response) return;
      localStorage.setItem("currentUser", JSON.stringify(response.data));
      navigate("/");
    } catch (err: unknown) {
      // @ts-ignore
      if (err && err.message) toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  });

  const loadRegulations = async () => {
    const { data } = await getRegulations();
    setRegulations(data);
  };

  useEffect(() => {
    loadRegulations();
  }, []);

  return (
    <Wrapper color="dark" dots>
      <Loader isLoading={isLoading} />
      <InputWrapper>
        <Typography.H3 variant="h3" color="yellow">
          {t("registerView.header")}
        </Typography.H3>
        <form onSubmit={onSubmit}>
          <TextField
            type="email"
            label={t("form.email.label")}
            placeholder={t("form.email.placeholder")}
            {...register("email")}
            error={t(errors.email?.message || "")}
            autoComplete="email"
          />
          <TextField
            type="password"
            label={t("form.password.label")}
            placeholder={t("form.password.placeholder")}
            {...register("password")}
            error={t(errors.password?.message || "")}
            autoComplete="password"
          />
          <TextField
            type="password"
            label={t("form.repeatPassword.label")}
            placeholder={t("form.repeatPassword.placeholder")}
            {...register("passwordConfirmation")}
            error={t(errors.passwordConfirmation?.message || "")}
            autoComplete="new-password"
          />
          <Flex
            flexWrap={"nowrap"}
            flexDirection={"row"}
            gridColumnGap={"15px"}
          >
            <TextField
              label={t("form.firstName.label")}
              placeholder={t("form.firstName.placeholder")}
              {...register("firstName")}
              error={t(errors.firstName?.message || "")}
              autoComplete="given-name"
            />
            <TextField
              label={t("form.lastName.label")}
              placeholder={t("form.lastName.placeholder")}
              {...register("lastName")}
              error={t(errors.lastName?.message || "")}
              autoComplete="family-name"
            />
          </Flex>
          <Controller
            control={control}
            name="regulationIds"
            render={({ field: { onChange } }) => (
              <>
                {regulations?.map((regulation) => (
                  <Box
                    display="flex"
                    justifyContent="start"
                    mb={4}
                    alignItems="center"
                    key={regulation.id}
                  >
                    <Checkbox
                      onChange={(newValue) => {
                        const checked = newValue.target.checked;
                        const currentValue = watch("regulationIds");

                        onChange(
                          checked
                            ? currentValue.includes(regulation.id)
                              ? currentValue
                              : [...currentValue, regulation.id]
                            : currentValue.filter(
                                (item) => item !== regulation.id,
                              ),
                        );
                      }}
                      label={t("form.accepting")}
                      labelLink={{
                        text: regulation.name,
                        href: `/info${regulation.link}`,
                      }}
                    />
                  </Box>
                ))}
              </>
            )}
          />
          <Box my={3}>
            <Button
              type="submit"
              disabled={!formState.isValid || !requiredRegulationsChecked}
              variant="secondary"
            >
              {t("buttons.createAccount")}
            </Button>
          </Box>
        </form>
        <Flex
          flexDirection={"row"}
          flexWrap={"nowrap"}
          alignItems="center"
          gridColumnGap={"10px"}
        >
          <Typography.P variant="body" color="secondary">
            {t("accountAlready")}
          </Typography.P>
          <Button variant="secondary" textOnly>
            <Link to="/login"> {t("buttons.signIn")}</Link>
          </Button>
        </Flex>
      </InputWrapper>
      <Image src={RegisterImage} alt="" />
    </Wrapper>
  );
};
