import { IBodyPart, IExercise } from "types/exercises";
import request from "./index";
import { AxiosResponse } from "axios";

export const getBodyParts = () =>
  request<{ data: IBodyPart[] }>({
    options: {
      url: `/body_parts`,
      method: "get",
    },
  });
