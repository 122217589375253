import { useAccount } from "hooks/useAccount";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isLogged } = useAccount();
  if (!isLogged) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};
